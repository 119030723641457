import { useUserGetNavigationQuery } from "../../store/deinVerbandApi";
import { useMemo } from "react";
import { Icon } from '@iconify/react';

export const useDynamicSections = () => {
    const { data } = useUserGetNavigationQuery();

    return useMemo(() => {
        return [
            {
                items: data?.navigation?.map((item) => {
                    return {
                        title: item.navigationName ?? "Unknown",
                        path: "/verband/" + item?.navigationModule,
                        icon: (<Icon
                            icon={`fa:${item.navigationIcon ?? 'question-circle-o'}`}
                            style={{ width: 20, height: 20 }}
                        />),
                    };
                }) ?? []
            }
        ];
    }, [data]);
}