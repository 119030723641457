import { combineReducers } from '@reduxjs/toolkit';
import { reducer as calendarReducer } from 'src/slices/calendar';
import { reducer as chatReducer } from 'src/slices/chat';
import { reducer as kanbanReducer } from 'src/slices/kanban';
import { reducer as mailReducer } from 'src/slices/mail';
import {emptySplitApi} from "./emptyApi";
import {tenantApi} from "./tenant-api";

export const rootReducer = combineReducers({
  calendar: calendarReducer,
  chat: chatReducer,
  kanban: kanbanReducer,
  mail: mailReducer,
  [emptySplitApi.reducerPath]: emptySplitApi.reducer,
  [tenantApi.reducerPath]: tenantApi.reducer,
});
