import {deinVerbandApi, UserLoginApiArg, UserLoginApiResponse} from "./deinVerbandApi";
import {EndpointDefinition} from "@reduxjs/toolkit/query";

export const api = deinVerbandApi.injectEndpoints({
    endpoints: (build) => ({
        login: build.mutation<UserLoginApiResponse, UserLoginApiArg>({
            query: (queryArg) => ({
                url: `/user/login.html`,
                method: 'POST',
                body: `mail=${queryArg.body?.mail}&password=${queryArg.body?.password}&associationID=${queryArg.body?.associationID}`,
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
            }),
            invalidatesTags: ['user'],
        }),
    }),
});

export const {
    useLoginMutation,
} = api;
