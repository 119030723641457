import { emptySplitApi as api } from "./emptyApi";
export const addTagTypes = [
  "statistic",
  "association",
  "link",
  "property",
  "user",
  "mail",
  "notification",
  "exception",
  "guild",
  "general",
  "appointment",
  "media",
  "rss",
  "file",
  "import",
  "document",
  "company",
  "discussion",
  "news",
] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      statisticGetProductivity: build.query<
        StatisticGetProductivityApiResponse,
        StatisticGetProductivityApiArg
      >({
        query: () => ({ url: `/statistic/getProductivity.html` }),
        providesTags: ["statistic"],
      }),
      statisticGetCore: build.query<
        StatisticGetCoreApiResponse,
        StatisticGetCoreApiArg
      >({
        query: () => ({ url: `/statistic/getCore.html` }),
        providesTags: ["statistic"],
      }),
      associationGetStyle: build.query<
        AssociationGetStyleApiResponse,
        AssociationGetStyleApiArg
      >({
        query: (queryArg) => ({
          url: `/association/getStyle.html`,
          params: { associationID: queryArg.associationId },
        }),
        providesTags: ["association"],
      }),
      associationGetStyleByDomain: build.query<
        AssociationGetStyleByDomainApiResponse,
        AssociationGetStyleByDomainApiArg
      >({
        query: (queryArg) => ({
          url: `/association/getStyleByDomain.html`,
          params: { domain: queryArg.domain },
        }),
        providesTags: ["association"],
      }),
      associationGetAll: build.query<
        AssociationGetAllApiResponse,
        AssociationGetAllApiArg
      >({
        query: (queryArg) => ({
          url: `/association/getAll.html`,
          params: {
            search: queryArg.search,
            limit: queryArg.limit,
            offset: queryArg.offset,
          },
        }),
        providesTags: ["association"],
      }),
      associationGet: build.query<
        AssociationGetApiResponse,
        AssociationGetApiArg
      >({
        query: (queryArg) => ({
          url: `/association/get.html`,
          params: { id: queryArg.id },
        }),
        providesTags: ["association"],
      }),
      associationSet: build.mutation<
        AssociationSetApiResponse,
        AssociationSetApiArg
      >({
        query: (queryArg) => ({
          url: `/association/set.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["association"],
      }),
      associationDelete: build.mutation<
        AssociationDeleteApiResponse,
        AssociationDeleteApiArg
      >({
        query: (queryArg) => ({
          url: `/association/delete.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["association"],
      }),
      associationSetDomainAdmin: build.mutation<
        AssociationSetDomainAdminApiResponse,
        AssociationSetDomainAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/association/setDomainAdmin.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["association"],
      }),
      associationSetUseTemplateAdmin: build.mutation<
        AssociationSetUseTemplateAdminApiResponse,
        AssociationSetUseTemplateAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/association/setUseTemplateAdmin.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["association"],
      }),
      associationSetTemplateFileAdmin: build.mutation<
        AssociationSetTemplateFileAdminApiResponse,
        AssociationSetTemplateFileAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/association/setTemplateFileAdmin.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["association"],
      }),
      associationDeleteImageAdmin: build.mutation<
        AssociationDeleteImageAdminApiResponse,
        AssociationDeleteImageAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/association/deleteImageAdmin.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["association"],
      }),
      associationGetAdminUserAll: build.query<
        AssociationGetAdminUserAllApiResponse,
        AssociationGetAdminUserAllApiArg
      >({
        query: (queryArg) => ({
          url: `/association/getAdminUserAll.html`,
          params: { id: queryArg.id },
        }),
        providesTags: ["association"],
      }),
      associationGetImagesAllAdmin: build.query<
        AssociationGetImagesAllAdminApiResponse,
        AssociationGetImagesAllAdminApiArg
      >({
        query: () => ({ url: `/association/getImagesAllAdmin.html` }),
        providesTags: ["association"],
      }),
      associationAddImageAdmin: build.mutation<
        AssociationAddImageAdminApiResponse,
        AssociationAddImageAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/association/addImageAdmin.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["association"],
      }),
      associationSetLetterAdmin: build.mutation<
        AssociationSetLetterAdminApiResponse,
        AssociationSetLetterAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/association/setLetterAdmin.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["association"],
      }),
      associationGetLetterAllAdmin: build.query<
        AssociationGetLetterAllAdminApiResponse,
        AssociationGetLetterAllAdminApiArg
      >({
        query: () => ({ url: `/association/getLetterAllAdmin.html` }),
        providesTags: ["association"],
      }),
      associationGetLetterAdmin: build.query<
        AssociationGetLetterAdminApiResponse,
        AssociationGetLetterAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/association/getLetterAdmin.html`,
          params: { id: queryArg.id },
        }),
        providesTags: ["association"],
      }),
      associationDeleteAssociationLetterAdmin: build.mutation<
        AssociationDeleteAssociationLetterAdminApiResponse,
        AssociationDeleteAssociationLetterAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/association/deleteAssociationLetterAdmin.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["association"],
      }),
      associationSetGeneral: build.mutation<
        AssociationSetGeneralApiResponse,
        AssociationSetGeneralApiArg
      >({
        query: (queryArg) => ({
          url: `/association/setGeneral.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["association"],
      }),
      associationSetSettingsMode: build.mutation<
        AssociationSetSettingsModeApiResponse,
        AssociationSetSettingsModeApiArg
      >({
        query: (queryArg) => ({
          url: `/association/setSettingsMode.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["association"],
      }),
      associationSetGuildAdmin: build.mutation<
        AssociationSetGuildAdminApiResponse,
        AssociationSetGuildAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/association/setGuildAdmin.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["association"],
      }),
      associationDeleteGuildAdmin: build.mutation<
        AssociationDeleteGuildAdminApiResponse,
        AssociationDeleteGuildAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/association/deleteGuildAdmin.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["association"],
      }),
      associationGetGuildAdmin: build.query<
        AssociationGetGuildAdminApiResponse,
        AssociationGetGuildAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/association/getGuildAdmin.html`,
          params: { guildID: queryArg.guildId },
        }),
        providesTags: ["association"],
      }),
      associationGetGuildReceivedAllAdmin: build.query<
        AssociationGetGuildReceivedAllAdminApiResponse,
        AssociationGetGuildReceivedAllAdminApiArg
      >({
        query: () => ({ url: `/association/getGuildReceivedAllAdmin.html` }),
        providesTags: ["association"],
      }),
      associationGetGuildSharedAdminAll: build.query<
        AssociationGetGuildSharedAdminAllApiResponse,
        AssociationGetGuildSharedAdminAllApiArg
      >({
        query: () => ({ url: `/association/getGuildSharedAdminAll.html` }),
        providesTags: ["association"],
      }),
      associationGetErrorsCount: build.query<
        AssociationGetErrorsCountApiResponse,
        AssociationGetErrorsCountApiArg
      >({
        query: () => ({ url: `/association/getErrorsCount.html` }),
        providesTags: ["association"],
      }),
      associationGetErrors: build.query<
        AssociationGetErrorsApiResponse,
        AssociationGetErrorsApiArg
      >({
        query: () => ({ url: `/association/getErrors.html` }),
        providesTags: ["association"],
      }),
      associationSetErrorResend: build.mutation<
        AssociationSetErrorResendApiResponse,
        AssociationSetErrorResendApiArg
      >({
        query: (queryArg) => ({
          url: `/association/setErrorResend.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["association"],
      }),
      associationSetErrorDelete: build.mutation<
        AssociationSetErrorDeleteApiResponse,
        AssociationSetErrorDeleteApiArg
      >({
        query: (queryArg) => ({
          url: `/association/setErrorDelete.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["association"],
      }),
      linkGetAll: build.query<LinkGetAllApiResponse, LinkGetAllApiArg>({
        query: () => ({ url: `/link/getAll.html` }),
        providesTags: ["link"],
      }),
      linkGetAllAdmin: build.query<
        LinkGetAllAdminApiResponse,
        LinkGetAllAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/link/getAllAdmin.html`,
          params: {
            offset: queryArg.offset,
            limit: queryArg.limit,
            search: queryArg.search,
            guildID: queryArg.guildId,
            companyTypeID: queryArg.companyTypeId,
          },
        }),
        providesTags: ["link"],
      }),
      linkGetListAdmin: build.query<
        LinkGetListAdminApiResponse,
        LinkGetListAdminApiArg
      >({
        query: () => ({ url: `/link/getListAdmin.html` }),
        providesTags: ["link"],
      }),
      linkGetAdviceAllAdmin: build.query<
        LinkGetAdviceAllAdminApiResponse,
        LinkGetAdviceAllAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/link/getAdviceAllAdmin.html`,
          params: {
            guildID: queryArg.guildId,
            offset: queryArg.offset,
            limit: queryArg.limit,
            search: queryArg.search,
          },
        }),
        providesTags: ["link"],
      }),
      linkDeleteAdmin: build.mutation<
        LinkDeleteAdminApiResponse,
        LinkDeleteAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/link/deleteAdmin.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["link"],
      }),
      linkDeleteAdviceAdmin: build.mutation<
        LinkDeleteAdviceAdminApiResponse,
        LinkDeleteAdviceAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/link/deleteAdviceAdmin.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["link"],
      }),
      linkSetGroupAdmin: build.mutation<
        LinkSetGroupAdminApiResponse,
        LinkSetGroupAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/link/setGroupAdmin.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["link"],
      }),
      linkDeleteGroupAdmin: build.mutation<
        LinkDeleteGroupAdminApiResponse,
        LinkDeleteGroupAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/link/deleteGroupAdmin.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["link"],
      }),
      linkGetGroupAllAdmin: build.query<
        LinkGetGroupAllAdminApiResponse,
        LinkGetGroupAllAdminApiArg
      >({
        query: () => ({ url: `/link/getGroupAllAdmin.html` }),
        providesTags: ["link"],
      }),
      linkSetAdmin: build.mutation<LinkSetAdminApiResponse, LinkSetAdminApiArg>(
        {
          query: (queryArg) => ({
            url: `/link/setAdmin.html`,
            method: "POST",
            body: queryArg.body,
          }),
          invalidatesTags: ["link"],
        }
      ),
      linkGetAdmin: build.query<LinkGetAdminApiResponse, LinkGetAdminApiArg>({
        query: (queryArg) => ({
          url: `/link/getAdmin.html`,
          params: { linkID: queryArg.linkId },
        }),
        providesTags: ["link"],
      }),
      linkGetAdviceAdmin: build.query<
        LinkGetAdviceAdminApiResponse,
        LinkGetAdviceAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/link/getAdviceAdmin.html`,
          params: { linkID: queryArg.linkId },
        }),
        providesTags: ["link"],
      }),
      linkGetGroupAdmin: build.query<
        LinkGetGroupAdminApiResponse,
        LinkGetGroupAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/link/getGroupAdmin.html`,
          params: { linkGroupID: queryArg.linkGroupId },
        }),
        providesTags: ["link"],
      }),
      propertyGetDataAllAdmin: build.query<
        PropertyGetDataAllAdminApiResponse,
        PropertyGetDataAllAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/property/getDataAllAdmin.html`,
          params: { companyID: queryArg.companyId },
        }),
        providesTags: ["property"],
      }),
      propertyGetDataAll: build.query<
        PropertyGetDataAllApiResponse,
        PropertyGetDataAllApiArg
      >({
        query: () => ({ url: `/property/getDataAll.html` }),
        providesTags: ["property"],
      }),
      propertySetDataAll: build.mutation<
        PropertySetDataAllApiResponse,
        PropertySetDataAllApiArg
      >({
        query: (queryArg) => ({
          url: `/property/setDataAll.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["property"],
      }),
      propertyGetTabDataAdmin: build.query<
        PropertyGetTabDataAdminApiResponse,
        PropertyGetTabDataAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/property/getTabDataAdmin.html`,
          params: { id: queryArg.id, companyID: queryArg.companyId },
        }),
        providesTags: ["property"],
      }),
      propertySetFieldOptionAdmin: build.mutation<
        PropertySetFieldOptionAdminApiResponse,
        PropertySetFieldOptionAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/property/setFieldOptionAdmin.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["property"],
      }),
      propertySetFieldOptionPropertyAdmin: build.mutation<
        PropertySetFieldOptionPropertyAdminApiResponse,
        PropertySetFieldOptionPropertyAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/property/setFieldOptionPropertyAdmin.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["property"],
      }),
      propertySetFieldAdmin: build.mutation<
        PropertySetFieldAdminApiResponse,
        PropertySetFieldAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/property/setFieldAdmin.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["property"],
      }),
      propertySetValueAdmin: build.mutation<
        PropertySetValueAdminApiResponse,
        PropertySetValueAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/property/setValueAdmin.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["property"],
      }),
      propertyGetFieldAdmin: build.query<
        PropertyGetFieldAdminApiResponse,
        PropertyGetFieldAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/property/getFieldAdmin.html`,
          params: { id: queryArg.id, companyID: queryArg.companyId },
        }),
        providesTags: ["property"],
      }),
      propertyGetFieldTypeAdminAll: build.query<
        PropertyGetFieldTypeAdminAllApiResponse,
        PropertyGetFieldTypeAdminAllApiArg
      >({
        query: () => ({ url: `/property/getFieldTypeAdminAll.html` }),
        providesTags: ["property"],
      }),
      propertyGetTabAdminAll: build.query<
        PropertyGetTabAdminAllApiResponse,
        PropertyGetTabAdminAllApiArg
      >({
        query: () => ({ url: `/property/getTabAdminAll.html` }),
        providesTags: ["property"],
      }),
      propertyGetTabTypeAllAdmin: build.query<
        PropertyGetTabTypeAllAdminApiResponse,
        PropertyGetTabTypeAllAdminApiArg
      >({
        query: () => ({ url: `/property/getTabTypeAllAdmin.html` }),
        providesTags: ["property"],
      }),
      propertyGetTabAdmin: build.query<
        PropertyGetTabAdminApiResponse,
        PropertyGetTabAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/property/getTabAdmin.html`,
          params: { id: queryArg.id },
        }),
        providesTags: ["property"],
      }),
      propertySetTabAdmin: build.mutation<
        PropertySetTabAdminApiResponse,
        PropertySetTabAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/property/setTabAdmin.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["property"],
      }),
      propertyDeleteTabAdmin: build.mutation<
        PropertyDeleteTabAdminApiResponse,
        PropertyDeleteTabAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/property/deleteTabAdmin.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["property"],
      }),
      propertyDeleteFieldAdmin: build.mutation<
        PropertyDeleteFieldAdminApiResponse,
        PropertyDeleteFieldAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/property/deleteFieldAdmin.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["property"],
      }),
      propertyDeleteOptionAdmin: build.mutation<
        PropertyDeleteOptionAdminApiResponse,
        PropertyDeleteOptionAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/property/deleteOptionAdmin.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["property"],
      }),
      propertySetTabOrderAdmin: build.mutation<
        PropertySetTabOrderAdminApiResponse,
        PropertySetTabOrderAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/property/setTabOrderAdmin.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["property"],
      }),
      propertySetFieldOrderAdmin: build.mutation<
        PropertySetFieldOrderAdminApiResponse,
        PropertySetFieldOrderAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/property/setFieldOrderAdmin.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["property"],
      }),
      propertyGetTabBlockAdminAll: build.query<
        PropertyGetTabBlockAdminAllApiResponse,
        PropertyGetTabBlockAdminAllApiArg
      >({
        query: (queryArg) => ({
          url: `/property/getTabBlockAdminAll.html`,
          params: { tabID: queryArg.tabId },
        }),
        providesTags: ["property"],
      }),
      propertyGetTabBlockAdmin: build.query<
        PropertyGetTabBlockAdminApiResponse,
        PropertyGetTabBlockAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/property/getTabBlockAdmin.html`,
          params: { id: queryArg.id },
        }),
        providesTags: ["property"],
      }),
      propertySetTabBlockAdmin: build.mutation<
        PropertySetTabBlockAdminApiResponse,
        PropertySetTabBlockAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/property/setTabBlockAdmin.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["property"],
      }),
      propertyAddTabDocumentGroupAdmin: build.mutation<
        PropertyAddTabDocumentGroupAdminApiResponse,
        PropertyAddTabDocumentGroupAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/property/addTabDocumentGroupAdmin.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["property"],
      }),
      propertyGetTabDocumentGroupsAdmin: build.query<
        PropertyGetTabDocumentGroupsAdminApiResponse,
        PropertyGetTabDocumentGroupsAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/property/getTabDocumentGroupsAdmin.html`,
          params: { tabID: queryArg.tabId },
        }),
        providesTags: ["property"],
      }),
      propertyDeleteTabBlockAdmin: build.mutation<
        PropertyDeleteTabBlockAdminApiResponse,
        PropertyDeleteTabBlockAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/property/deleteTabBlockAdmin.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["property"],
      }),
      propertySetTabBlockOrderAdmin: build.mutation<
        PropertySetTabBlockOrderAdminApiResponse,
        PropertySetTabBlockOrderAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/property/setTabBlockOrderAdmin.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["property"],
      }),
      propertyGetTabDocumentAllAdmin: build.query<
        PropertyGetTabDocumentAllAdminApiResponse,
        PropertyGetTabDocumentAllAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/property/getTabDocumentAllAdmin.html`,
          params: { tabID: queryArg.tabId, companyID: queryArg.companyId },
        }),
        providesTags: ["property"],
      }),
      propertyGetTabTimelineAllAdmin: build.query<
        PropertyGetTabTimelineAllAdminApiResponse,
        PropertyGetTabTimelineAllAdminApiArg
      >({
        query: () => ({ url: `/property/getTabTimelineAllAdmin.html` }),
        providesTags: ["property"],
      }),
      propertySetTabDocumentAdmin: build.mutation<
        PropertySetTabDocumentAdminApiResponse,
        PropertySetTabDocumentAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/property/setTabDocumentAdmin.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["property"],
      }),
      propertySetTabTimelineAdmin: build.mutation<
        PropertySetTabTimelineAdminApiResponse,
        PropertySetTabTimelineAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/property/setTabTimelineAdmin.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["property"],
      }),
      propertyDeleteTabTimelineAdmin: build.mutation<
        PropertyDeleteTabTimelineAdminApiResponse,
        PropertyDeleteTabTimelineAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/property/deleteTabTimelineAdmin.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["property"],
      }),
      propertyDeleteTabDocumentAdmin: build.mutation<
        PropertyDeleteTabDocumentAdminApiResponse,
        PropertyDeleteTabDocumentAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/property/deleteTabDocumentAdmin.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["property"],
      }),
      propertyDeleteTabTimelineFileAdmin: build.mutation<
        PropertyDeleteTabTimelineFileAdminApiResponse,
        PropertyDeleteTabTimelineFileAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/property/deleteTabTimelineFileAdmin.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["property"],
      }),
      propertyGetExportDataAdmin: build.query<
        PropertyGetExportDataAdminApiResponse,
        PropertyGetExportDataAdminApiArg
      >({
        query: () => ({ url: `/property/getExportDataAdmin.html` }),
        providesTags: ["property"],
      }),
      propertyAddExportPresetItemAdmin: build.mutation<
        PropertyAddExportPresetItemAdminApiResponse,
        PropertyAddExportPresetItemAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/property/addExportPresetItemAdmin.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["property"],
      }),
      propertyAddExportPresetAdmin: build.mutation<
        PropertyAddExportPresetAdminApiResponse,
        PropertyAddExportPresetAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/property/addExportPresetAdmin.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["property"],
      }),
      propertyGetExportPresetAdmin: build.query<
        PropertyGetExportPresetAdminApiResponse,
        PropertyGetExportPresetAdminApiArg
      >({
        query: () => ({ url: `/property/getExportPresetAdmin.html` }),
        providesTags: ["property"],
      }),
      userLogin: build.mutation<UserLoginApiResponse, UserLoginApiArg>({
        query: (queryArg) => ({
          url: `/user/login.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["user"],
      }),
      userIsValidApikey: build.query<
        UserIsValidApikeyApiResponse,
        UserIsValidApikeyApiArg
      >({
        query: (queryArg) => ({
          url: `/user/isValidApikey.html`,
          params: { apikey: queryArg.apikey },
        }),
        providesTags: ["user"],
      }),
      userGetNavigation: build.query<
        UserGetNavigationApiResponse,
        UserGetNavigationApiArg
      >({
        query: () => ({ url: `/user/getNavigation.html` }),
        providesTags: ["user"],
      }),
      userGetNavigationItem: build.query<
        UserGetNavigationItemApiResponse,
        UserGetNavigationItemApiArg
      >({
        query: (queryArg) => ({
          url: `/user/getNavigationItem.html`,
          params: { id: queryArg.id },
        }),
        providesTags: ["user"],
      }),
      userSetNavigationCountEmpty: build.mutation<
        UserSetNavigationCountEmptyApiResponse,
        UserSetNavigationCountEmptyApiArg
      >({
        query: (queryArg) => ({
          url: `/user/setNavigationCountEmpty.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["user"],
      }),
      userGetDetails: build.query<
        UserGetDetailsApiResponse,
        UserGetDetailsApiArg
      >({
        query: () => ({ url: `/user/getDetails.html` }),
        providesTags: ["user"],
      }),
      userGetDeviceAdmin: build.query<
        UserGetDeviceAdminApiResponse,
        UserGetDeviceAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/user/getDeviceAdmin.html`,
          params: { id: queryArg.id },
        }),
        providesTags: ["user"],
      }),
      userDeleteDevice: build.mutation<
        UserDeleteDeviceApiResponse,
        UserDeleteDeviceApiArg
      >({
        query: (queryArg) => ({
          url: `/user/deleteDevice.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["user"],
      }),
      userAddDevice: build.mutation<
        UserAddDeviceApiResponse,
        UserAddDeviceApiArg
      >({
        query: (queryArg) => ({
          url: `/user/addDevice.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["user"],
      }),
      userSendLoginAdmin: build.mutation<
        UserSendLoginAdminApiResponse,
        UserSendLoginAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/user/sendLoginAdmin.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["user"],
      }),
      userGetAllAdmin: build.query<
        UserGetAllAdminApiResponse,
        UserGetAllAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/user/getAllAdmin.html`,
          params: {
            id: queryArg.id,
            offset: queryArg.offset,
            search: queryArg.search,
            limit: queryArg.limit,
          },
        }),
        providesTags: ["user"],
      }),
      userGetAdminAllAdmin: build.query<
        UserGetAdminAllAdminApiResponse,
        UserGetAdminAllAdminApiArg
      >({
        query: () => ({ url: `/user/getAdminAllAdmin.html` }),
        providesTags: ["user"],
      }),
      userGetAdmin: build.query<UserGetAdminApiResponse, UserGetAdminApiArg>({
        query: (queryArg) => ({
          url: `/user/getAdmin.html`,
          params: { id: queryArg.id },
        }),
        providesTags: ["user"],
      }),
      userSetAdmin: build.mutation<UserSetAdminApiResponse, UserSetAdminApiArg>(
        {
          query: (queryArg) => ({
            url: `/user/setAdmin.html`,
            method: "POST",
            body: queryArg.body,
          }),
          invalidatesTags: ["user"],
        }
      ),
      userDeleteUserAdmin: build.mutation<
        UserDeleteUserAdminApiResponse,
        UserDeleteUserAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/user/deleteUserAdmin.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["user"],
      }),
      userAddGuildAdmin: build.mutation<
        UserAddGuildAdminApiResponse,
        UserAddGuildAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/user/addGuildAdmin.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["user"],
      }),
      userDeleteAdmin: build.mutation<
        UserDeleteAdminApiResponse,
        UserDeleteAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/user/deleteAdmin.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["user"],
      }),
      userDeleteGuildAdmin: build.mutation<
        UserDeleteGuildAdminApiResponse,
        UserDeleteGuildAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/user/deleteGuildAdmin.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["user"],
      }),
      userSetPasswordRequest: build.mutation<
        UserSetPasswordRequestApiResponse,
        UserSetPasswordRequestApiArg
      >({
        query: (queryArg) => ({
          url: `/user/setPasswordRequest.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["user"],
      }),
      userResetPassword: build.mutation<
        UserResetPasswordApiResponse,
        UserResetPasswordApiArg
      >({
        query: (queryArg) => ({
          url: `/user/resetPassword.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["user"],
      }),
      userSetChanges: build.mutation<
        UserSetChangesApiResponse,
        UserSetChangesApiArg
      >({
        query: (queryArg) => ({
          url: `/user/setChanges.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["user"],
      }),
      userGetChanges: build.query<
        UserGetChangesApiResponse,
        UserGetChangesApiArg
      >({
        query: () => ({ url: `/user/getChanges.html` }),
        providesTags: ["user"],
      }),
      userGetChangesAllAdmin: build.query<
        UserGetChangesAllAdminApiResponse,
        UserGetChangesAllAdminApiArg
      >({
        query: () => ({ url: `/user/getChangesAllAdmin.html` }),
        providesTags: ["user"],
      }),
      userSetChangeAdmin: build.mutation<
        UserSetChangeAdminApiResponse,
        UserSetChangeAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/user/setChangeAdmin.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["user"],
      }),
      userDeleteChangesAdmin: build.mutation<
        UserDeleteChangesAdminApiResponse,
        UserDeleteChangesAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/user/deleteChangesAdmin.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["user"],
      }),
      userGetByActivationKey: build.query<
        UserGetByActivationKeyApiResponse,
        UserGetByActivationKeyApiArg
      >({
        query: (queryArg) => ({
          url: `/user/getByActivationKey.html`,
          params: { key: queryArg.key },
        }),
        providesTags: ["user"],
      }),
      mailGetAccountAllAdmin: build.query<
        MailGetAccountAllAdminApiResponse,
        MailGetAccountAllAdminApiArg
      >({
        query: () => ({ url: `/mail/getAccountAllAdmin.html` }),
        providesTags: ["mail"],
      }),
      mailGetAccountAdmin: build.query<
        MailGetAccountAdminApiResponse,
        MailGetAccountAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/mail/getAccountAdmin.html`,
          params: { id: queryArg.id },
        }),
        providesTags: ["mail"],
      }),
      mailGetAccountTypeAllAdmin: build.query<
        MailGetAccountTypeAllAdminApiResponse,
        MailGetAccountTypeAllAdminApiArg
      >({
        query: () => ({ url: `/mail/getAccountTypeAllAdmin.html` }),
        providesTags: ["mail"],
      }),
      mailDeleteAccountAdmin: build.mutation<
        MailDeleteAccountAdminApiResponse,
        MailDeleteAccountAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/mail/deleteAccountAdmin.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["mail"],
      }),
      mailSetAccountActiveAdmin: build.mutation<
        MailSetAccountActiveAdminApiResponse,
        MailSetAccountActiveAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/mail/setAccountActiveAdmin.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["mail"],
      }),
      mailSetSignatureActiveAdmin: build.mutation<
        MailSetSignatureActiveAdminApiResponse,
        MailSetSignatureActiveAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/mail/setSignatureActiveAdmin.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["mail"],
      }),
      mailAddSignatureAdmin: build.mutation<
        MailAddSignatureAdminApiResponse,
        MailAddSignatureAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/mail/addSignatureAdmin.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["mail"],
      }),
      mailGetSignatureAllAdmin: build.query<
        MailGetSignatureAllAdminApiResponse,
        MailGetSignatureAllAdminApiArg
      >({
        query: () => ({ url: `/mail/getSignatureAllAdmin.html` }),
        providesTags: ["mail"],
      }),
      mailGetSignatureAdmin: build.query<
        MailGetSignatureAdminApiResponse,
        MailGetSignatureAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/mail/getSignatureAdmin.html`,
          params: { id: queryArg.id },
        }),
        providesTags: ["mail"],
      }),
      mailEditSignatureAdmin: build.mutation<
        MailEditSignatureAdminApiResponse,
        MailEditSignatureAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/mail/editSignatureAdmin.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["mail"],
      }),
      mailDeleteSignatureAdmin: build.mutation<
        MailDeleteSignatureAdminApiResponse,
        MailDeleteSignatureAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/mail/deleteSignatureAdmin.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["mail"],
      }),
      mailGetMailContentAdmin: build.query<
        MailGetMailContentAdminApiResponse,
        MailGetMailContentAdminApiArg
      >({
        query: () => ({ url: `/mail/getMailContentAdmin.html` }),
        providesTags: ["mail"],
      }),
      mailSetMailAdmin: build.mutation<
        MailSetMailAdminApiResponse,
        MailSetMailAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/mail/setMailAdmin.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["mail"],
      }),
      mailSetAccountAdmin: build.mutation<
        MailSetAccountAdminApiResponse,
        MailSetAccountAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/mail/setAccountAdmin.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["mail"],
      }),
      mailSetAccountAdminOffice365: build.mutation<
        MailSetAccountAdminOffice365ApiResponse,
        MailSetAccountAdminOffice365ApiArg
      >({
        query: (queryArg) => ({
          url: `/mail/setAccountAdminOffice365.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["mail"],
      }),
      mailGetTemplatesAllAdmin: build.query<
        MailGetTemplatesAllAdminApiResponse,
        MailGetTemplatesAllAdminApiArg
      >({
        query: () => ({ url: `/mail/getTemplatesAllAdmin.html` }),
        providesTags: ["mail"],
      }),
      notificationGetTemplatesAll: build.query<
        NotificationGetTemplatesAllApiResponse,
        NotificationGetTemplatesAllApiArg
      >({
        query: () => ({ url: `/notification/getTemplatesAll.html` }),
        providesTags: ["notification"],
      }),
      notificationGetTemplate: build.query<
        NotificationGetTemplateApiResponse,
        NotificationGetTemplateApiArg
      >({
        query: (queryArg) => ({
          url: `/notification/getTemplate.html`,
          params: { id: queryArg.id },
        }),
        providesTags: ["notification"],
      }),
      notificationDeleteTemplate: build.mutation<
        NotificationDeleteTemplateApiResponse,
        NotificationDeleteTemplateApiArg
      >({
        query: (queryArg) => ({
          url: `/notification/deleteTemplate.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["notification"],
      }),
      notificationAddTemplateEmpty: build.mutation<
        NotificationAddTemplateEmptyApiResponse,
        NotificationAddTemplateEmptyApiArg
      >({
        query: (queryArg) => ({
          url: `/notification/addTemplateEmpty.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["notification"],
      }),
      notificationSetTemplate: build.mutation<
        NotificationSetTemplateApiResponse,
        NotificationSetTemplateApiArg
      >({
        query: (queryArg) => ({
          url: `/notification/setTemplate.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["notification"],
      }),
      notificationAddTest: build.mutation<
        NotificationAddTestApiResponse,
        NotificationAddTestApiArg
      >({
        query: (queryArg) => ({
          url: `/notification/addTest.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["notification"],
      }),
      notificationAutoDelete: build.mutation<
        NotificationAutoDeleteApiResponse,
        NotificationAutoDeleteApiArg
      >({
        query: (queryArg) => ({
          url: `/notification/autoDelete.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["notification"],
      }),
      notificationGenerateEpostFiles: build.query<
        NotificationGenerateEpostFilesApiResponse,
        NotificationGenerateEpostFilesApiArg
      >({
        query: () => ({ url: `/notification/generateEpostFiles.html` }),
        providesTags: ["notification"],
      }),
      notificationConvertPdfFile: build.mutation<
        NotificationConvertPdfFileApiResponse,
        NotificationConvertPdfFileApiArg
      >({
        query: (queryArg) => ({
          url: `/notification/convertPdfFile.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["notification"],
      }),
      notificationMergePdfFiles: build.mutation<
        NotificationMergePdfFilesApiResponse,
        NotificationMergePdfFilesApiArg
      >({
        query: (queryArg) => ({
          url: `/notification/mergePdfFiles.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["notification"],
      }),
      notificationResend: build.mutation<
        NotificationResendApiResponse,
        NotificationResendApiArg
      >({
        query: (queryArg) => ({
          url: `/notification/resend.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["notification"],
      }),
      notificationGetReceiverCounts: build.query<
        NotificationGetReceiverCountsApiResponse,
        NotificationGetReceiverCountsApiArg
      >({
        query: (queryArg) => ({
          url: `/notification/getReceiverCounts.html`,
          params: {
            shares: queryArg.shares,
            shareApp: queryArg.shareApp,
            shareMail: queryArg.shareMail,
            shareEpost: queryArg.shareEpost,
          },
        }),
        providesTags: ["notification"],
      }),
      notificationSetReceiverCounts: build.mutation<
        NotificationSetReceiverCountsApiResponse,
        NotificationSetReceiverCountsApiArg
      >({
        query: (queryArg) => ({
          url: `/notification/setReceiverCounts.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["notification"],
      }),
      notificationGetImageInfo: build.query<
        NotificationGetImageInfoApiResponse,
        NotificationGetImageInfoApiArg
      >({
        query: (queryArg) => ({
          url: `/notification/getImageInfo.html`,
          params: { identifier: queryArg.identifier },
        }),
        providesTags: ["notification"],
      }),
      notificationGetTypes: build.query<
        NotificationGetTypesApiResponse,
        NotificationGetTypesApiArg
      >({
        query: () => ({ url: `/notification/getTypes.html` }),
        providesTags: ["notification"],
      }),
      notificationSetTypes: build.mutation<
        NotificationSetTypesApiResponse,
        NotificationSetTypesApiArg
      >({
        query: (queryArg) => ({
          url: `/notification/setTypes.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["notification"],
      }),
      notificationGetUserAllAdmin: build.query<
        NotificationGetUserAllAdminApiResponse,
        NotificationGetUserAllAdminApiArg
      >({
        query: () => ({ url: `/notification/getUserAllAdmin.html` }),
        providesTags: ["notification"],
      }),
      notificationSetUserAllAdmin: build.mutation<
        NotificationSetUserAllAdminApiResponse,
        NotificationSetUserAllAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/notification/setUserAllAdmin.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["notification"],
      }),
      notificationDeleteUserAdmin: build.mutation<
        NotificationDeleteUserAdminApiResponse,
        NotificationDeleteUserAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/notification/deleteUserAdmin.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["notification"],
      }),
      notificationAddUserAdmin: build.mutation<
        NotificationAddUserAdminApiResponse,
        NotificationAddUserAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/notification/addUserAdmin.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["notification"],
      }),
      notificationAddTestMail: build.mutation<
        NotificationAddTestMailApiResponse,
        NotificationAddTestMailApiArg
      >({
        query: (queryArg) => ({
          url: `/notification/addTestMail.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["notification"],
      }),
      notificationRefreshAccessTokens: build.mutation<
        NotificationRefreshAccessTokensApiResponse,
        NotificationRefreshAccessTokensApiArg
      >({
        query: (queryArg) => ({
          url: `/notification/refreshAccessTokens.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["notification"],
      }),
      exceptionException: build.mutation<
        ExceptionExceptionApiResponse,
        ExceptionExceptionApiArg
      >({
        query: (queryArg) => ({
          url: `/exception/exception.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["exception"],
      }),
      guildGetAllAdmin: build.query<
        GuildGetAllAdminApiResponse,
        GuildGetAllAdminApiArg
      >({
        query: () => ({ url: `/guild/getAllAdmin.html` }),
        providesTags: ["guild"],
      }),
      guildGetAdminAllAdmin: build.query<
        GuildGetAdminAllAdminApiResponse,
        GuildGetAdminAllAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/guild/getAdminAllAdmin.html`,
          params: { userID: queryArg.userId },
        }),
        providesTags: ["guild"],
      }),
      guildGetAdmin: build.query<GuildGetAdminApiResponse, GuildGetAdminApiArg>(
        {
          query: (queryArg) => ({
            url: `/guild/getAdmin.html`,
            params: { id: queryArg.id },
          }),
          providesTags: ["guild"],
        }
      ),
      guildSetAdmin: build.mutation<
        GuildSetAdminApiResponse,
        GuildSetAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/guild/setAdmin.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["guild"],
      }),
      guildDeleteAdmin: build.mutation<
        GuildDeleteAdminApiResponse,
        GuildDeleteAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/guild/deleteAdmin.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["guild"],
      }),
      guildSetNotifictionTemplate: build.mutation<
        GuildSetNotifictionTemplateApiResponse,
        GuildSetNotifictionTemplateApiArg
      >({
        query: (queryArg) => ({
          url: `/guild/setNotifictionTemplate.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["guild"],
      }),
      guildGetNotificationTemplate: build.query<
        GuildGetNotificationTemplateApiResponse,
        GuildGetNotificationTemplateApiArg
      >({
        query: (queryArg) => ({
          url: `/guild/getNotificationTemplate.html`,
          params: { guildID: queryArg.guildId },
        }),
        providesTags: ["guild"],
      }),
      guildGetUserAllAdmin: build.query<
        GuildGetUserAllAdminApiResponse,
        GuildGetUserAllAdminApiArg
      >({
        query: () => ({ url: `/guild/getUserAllAdmin.html` }),
        providesTags: ["guild"],
      }),
      guildAddAdminAdmin: build.mutation<
        GuildAddAdminAdminApiResponse,
        GuildAddAdminAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/guild/addAdminAdmin.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["guild"],
      }),
      guildDeleteAdminAdmin: build.mutation<
        GuildDeleteAdminAdminApiResponse,
        GuildDeleteAdminAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/guild/deleteAdminAdmin.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["guild"],
      }),
      generalGetChangelog: build.query<
        GeneralGetChangelogApiResponse,
        GeneralGetChangelogApiArg
      >({
        query: () => ({ url: `/general/getChangelog.html` }),
        providesTags: ["general"],
      }),
      appointmentGetAll: build.query<
        AppointmentGetAllApiResponse,
        AppointmentGetAllApiArg
      >({
        query: () => ({ url: `/appointment/getAll.html` }),
        providesTags: ["appointment"],
      }),
      appointmentSetAppointment: build.mutation<
        AppointmentSetAppointmentApiResponse,
        AppointmentSetAppointmentApiArg
      >({
        query: (queryArg) => ({
          url: `/appointment/setAppointment.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["appointment"],
      }),
      appointmentGetAdviceAllAdmin: build.query<
        AppointmentGetAdviceAllAdminApiResponse,
        AppointmentGetAdviceAllAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/appointment/getAdviceAllAdmin.html`,
          params: {
            offset: queryArg.offset,
            limit: queryArg.limit,
            guildID: queryArg.guildId,
            search: queryArg.search,
          },
        }),
        providesTags: ["appointment"],
      }),
      appointmentGetAdviceAdmin: build.query<
        AppointmentGetAdviceAdminApiResponse,
        AppointmentGetAdviceAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/appointment/getAdviceAdmin.html`,
          params: { appointmentID: queryArg.appointmentId },
        }),
        providesTags: ["appointment"],
      }),
      appointmentGet: build.query<
        AppointmentGetApiResponse,
        AppointmentGetApiArg
      >({
        query: (queryArg) => ({
          url: `/appointment/get.html`,
          params: { id: queryArg.id },
        }),
        providesTags: ["appointment"],
      }),
      appointmentSet: build.mutation<
        AppointmentSetApiResponse,
        AppointmentSetApiArg
      >({
        query: (queryArg) => ({
          url: `/appointment/set.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["appointment"],
      }),
      appointmentGetUsers: build.query<
        AppointmentGetUsersApiResponse,
        AppointmentGetUsersApiArg
      >({
        query: (queryArg) => ({
          url: `/appointment/getUsers.html`,
          params: { appointmentID: queryArg.appointmentId },
        }),
        providesTags: ["appointment"],
      }),
      appointmentDeleteDocumentsAdmin: build.mutation<
        AppointmentDeleteDocumentsAdminApiResponse,
        AppointmentDeleteDocumentsAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/appointment/deleteDocumentsAdmin.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["appointment"],
      }),
      appointmentSetStatus: build.mutation<
        AppointmentSetStatusApiResponse,
        AppointmentSetStatusApiArg
      >({
        query: (queryArg) => ({
          url: `/appointment/setStatus.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["appointment"],
      }),
      appointmentGetAllAdmin: build.query<
        AppointmentGetAllAdminApiResponse,
        AppointmentGetAllAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/appointment/getAllAdmin.html`,
          params: {
            offset: queryArg.offset,
            limit: queryArg.limit,
            guildID: queryArg.guildId,
            companyTypeID: queryArg.companyTypeId,
            search: queryArg.search,
          },
        }),
        providesTags: ["appointment"],
      }),
      appointmentDeleteAdmin: build.mutation<
        AppointmentDeleteAdminApiResponse,
        AppointmentDeleteAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/appointment/deleteAdmin.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["appointment"],
      }),
      appointmentDeleteAdviceAdmin: build.mutation<
        AppointmentDeleteAdviceAdminApiResponse,
        AppointmentDeleteAdviceAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/appointment/deleteAdviceAdmin.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["appointment"],
      }),
      appointmentShowIcsFrame: build.query<
        AppointmentShowIcsFrameApiResponse,
        AppointmentShowIcsFrameApiArg
      >({
        query: (queryArg) => ({
          url: `/appointment/showICSFrame.html`,
          params: { id: queryArg.id },
        }),
        providesTags: ["appointment"],
      }),
      appointmentGetUserAllAdmin: build.query<
        AppointmentGetUserAllAdminApiResponse,
        AppointmentGetUserAllAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/appointment/getUserAllAdmin.html`,
          params: {
            appointmentID: queryArg.appointmentId,
            userDeviceTypeID: queryArg.userDeviceTypeId,
          },
        }),
        providesTags: ["appointment"],
      }),
      mediaGetAll: build.query<MediaGetAllApiResponse, MediaGetAllApiArg>({
        query: (queryArg) => ({
          url: `/media/getAll.html`,
          params: { offset: queryArg.offset, limit: queryArg.limit },
        }),
        providesTags: ["media"],
      }),
      mediaGet: build.query<MediaGetApiResponse, MediaGetApiArg>({
        query: (queryArg) => ({
          url: `/media/get.html`,
          params: { id: queryArg.id },
        }),
        providesTags: ["media"],
      }),
      mediaDeleteAdmin: build.mutation<
        MediaDeleteAdminApiResponse,
        MediaDeleteAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/media/deleteAdmin.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["media"],
      }),
      mediaGetUserAllAdmin: build.query<
        MediaGetUserAllAdminApiResponse,
        MediaGetUserAllAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/media/getUserAllAdmin.html`,
          params: {
            mediaID: queryArg.mediaId,
            userDeviceTypeID: queryArg.userDeviceTypeId,
          },
        }),
        providesTags: ["media"],
      }),
      mediaSetAdmin: build.mutation<
        MediaSetAdminApiResponse,
        MediaSetAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/media/setAdmin.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["media"],
      }),
      mediaGetAdmin: build.query<MediaGetAdminApiResponse, MediaGetAdminApiArg>(
        {
          query: (queryArg) => ({
            url: `/media/getAdmin.html`,
            params: { mediaID: queryArg.mediaId },
          }),
          providesTags: ["media"],
        }
      ),
      mediaSetViewCount: build.mutation<
        MediaSetViewCountApiResponse,
        MediaSetViewCountApiArg
      >({
        query: (queryArg) => ({
          url: `/media/setViewCount.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["media"],
      }),
      rssGetAllAdmin: build.query<
        RssGetAllAdminApiResponse,
        RssGetAllAdminApiArg
      >({
        query: () => ({ url: `/rss/getAllAdmin.html` }),
        providesTags: ["rss"],
      }),
      rssDeleteAdmin: build.mutation<
        RssDeleteAdminApiResponse,
        RssDeleteAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/rss/deleteAdmin.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["rss"],
      }),
      rssGetAdmin: build.query<RssGetAdminApiResponse, RssGetAdminApiArg>({
        query: (queryArg) => ({
          url: `/rss/getAdmin.html`,
          params: { id: queryArg.id },
        }),
        providesTags: ["rss"],
      }),
      rssSetAdmin: build.mutation<RssSetAdminApiResponse, RssSetAdminApiArg>({
        query: (queryArg) => ({
          url: `/rss/setAdmin.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["rss"],
      }),
      rssFetchChannels: build.query<
        RssFetchChannelsApiResponse,
        RssFetchChannelsApiArg
      >({
        query: (queryArg) => ({
          url: `/rss/fetchChannels.html`,
          params: { url: queryArg.url, id: queryArg.id },
        }),
        providesTags: ["rss"],
      }),
      fileUpload: build.mutation<FileUploadApiResponse, FileUploadApiArg>({
        query: (queryArg) => ({
          url: `/file/upload.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["file"],
      }),
      fileGeneratePdf: build.mutation<
        FileGeneratePdfApiResponse,
        FileGeneratePdfApiArg
      >({
        query: (queryArg) => ({
          url: `/file/generatePdf.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["file"],
      }),
      importGetFields: build.query<
        ImportGetFieldsApiResponse,
        ImportGetFieldsApiArg
      >({
        query: () => ({ url: `/import/getFields.html` }),
        providesTags: ["import"],
      }),
      importGetPresets: build.query<
        ImportGetPresetsApiResponse,
        ImportGetPresetsApiArg
      >({
        query: (queryArg) => ({
          url: `/import/getPresets.html`,
          params: { associationID: queryArg.associationId },
        }),
        providesTags: ["import"],
      }),
      importGetPreset: build.query<
        ImportGetPresetApiResponse,
        ImportGetPresetApiArg
      >({
        query: (queryArg) => ({
          url: `/import/getPreset.html`,
          params: {
            fileName: queryArg.fileName,
            associationID: queryArg.associationId,
          },
        }),
        providesTags: ["import"],
      }),
      importAdd: build.mutation<ImportAddApiResponse, ImportAddApiArg>({
        query: (queryArg) => ({
          url: `/import/add.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["import"],
      }),
      importAddExcel: build.mutation<
        ImportAddExcelApiResponse,
        ImportAddExcelApiArg
      >({
        query: (queryArg) => ({
          url: `/import/addExcel.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["import"],
      }),
      importSetProcessActive: build.mutation<
        ImportSetProcessActiveApiResponse,
        ImportSetProcessActiveApiArg
      >({
        query: (queryArg) => ({
          url: `/import/setProcessActive.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["import"],
      }),
      importSetProcessInActive: build.mutation<
        ImportSetProcessInActiveApiResponse,
        ImportSetProcessInActiveApiArg
      >({
        query: (queryArg) => ({
          url: `/import/setProcessInActive.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["import"],
      }),
      documentGetGroupAll: build.query<
        DocumentGetGroupAllApiResponse,
        DocumentGetGroupAllApiArg
      >({
        query: (queryArg) => ({
          url: `/document/getGroupAll.html`,
          params: {
            search: queryArg.search,
            documentGroupID: queryArg.documentGroupId,
          },
        }),
        providesTags: ["document"],
      }),
      documentGetGroupAllAdmin: build.query<
        DocumentGetGroupAllAdminApiResponse,
        DocumentGetGroupAllAdminApiArg
      >({
        query: () => ({ url: `/document/getGroupAllAdmin.html` }),
        providesTags: ["document"],
      }),
      documentSetGroupAdmin: build.mutation<
        DocumentSetGroupAdminApiResponse,
        DocumentSetGroupAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/document/setGroupAdmin.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["document"],
      }),
      documentDeleteGroupAdmin: build.mutation<
        DocumentDeleteGroupAdminApiResponse,
        DocumentDeleteGroupAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/document/deleteGroupAdmin.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["document"],
      }),
      documentGetGroup: build.query<
        DocumentGetGroupApiResponse,
        DocumentGetGroupApiArg
      >({
        query: (queryArg) => ({
          url: `/document/getGroup.html`,
          params: { id: queryArg.id },
        }),
        providesTags: ["document"],
      }),
      documentGetAll: build.query<
        DocumentGetAllApiResponse,
        DocumentGetAllApiArg
      >({
        query: (queryArg) => ({
          url: `/document/getAll.html`,
          params: {
            id: queryArg.id,
            offset: queryArg.offset,
            limit: queryArg.limit,
            search: queryArg.search,
          },
        }),
        providesTags: ["document"],
      }),
      documentGet: build.query<DocumentGetApiResponse, DocumentGetApiArg>({
        query: (queryArg) => ({
          url: `/document/get.html`,
          params: { id: queryArg.id },
        }),
        providesTags: ["document"],
      }),
      documentGetByAffiliationId: build.query<
        DocumentGetByAffiliationIdApiResponse,
        DocumentGetByAffiliationIdApiArg
      >({
        query: (queryArg) => ({
          url: `/document/getByAffiliationID.html`,
          params: { id: queryArg.id },
        }),
        providesTags: ["document"],
      }),
      documentSetAdmin: build.mutation<
        DocumentSetAdminApiResponse,
        DocumentSetAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/document/setAdmin.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["document"],
      }),
      documentSetImportAdmin: build.mutation<
        DocumentSetImportAdminApiResponse,
        DocumentSetImportAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/document/setImportAdmin.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["document"],
      }),
      documentGetAllAdmin: build.query<
        DocumentGetAllAdminApiResponse,
        DocumentGetAllAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/document/getAllAdmin.html`,
          params: {
            search: queryArg.search,
            offset: queryArg.offset,
            limit: queryArg.limit,
            guildID: queryArg.guildId,
            companyTypeID: queryArg.companyTypeId,
          },
        }),
        providesTags: ["document"],
      }),
      documentGetAllByDateAdmin: build.query<
        DocumentGetAllByDateAdminApiResponse,
        DocumentGetAllByDateAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/document/getAllByDateAdmin.html`,
          params: { offset: queryArg.offset, limit: queryArg.limit },
        }),
        providesTags: ["document"],
      }),
      documentGetAdviceAllAdmin: build.query<
        DocumentGetAdviceAllAdminApiResponse,
        DocumentGetAdviceAllAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/document/getAdviceAllAdmin.html`,
          params: {
            search: queryArg.search,
            offset: queryArg.offset,
            limit: queryArg.limit,
            guildID: queryArg.guildId,
          },
        }),
        providesTags: ["document"],
      }),
      documentDeleteAdmin: build.mutation<
        DocumentDeleteAdminApiResponse,
        DocumentDeleteAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/document/deleteAdmin.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["document"],
      }),
      documentDeleteMultipleAdmin: build.mutation<
        DocumentDeleteMultipleAdminApiResponse,
        DocumentDeleteMultipleAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/document/deleteMultipleAdmin.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["document"],
      }),
      documentDeleteAdviceAdmin: build.mutation<
        DocumentDeleteAdviceAdminApiResponse,
        DocumentDeleteAdviceAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/document/deleteAdviceAdmin.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["document"],
      }),
      documentGetAdmin: build.query<
        DocumentGetAdminApiResponse,
        DocumentGetAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/document/getAdmin.html`,
          params: { documentID: queryArg.documentId },
        }),
        providesTags: ["document"],
      }),
      documentGetAffiliationAdmin: build.query<
        DocumentGetAffiliationAdminApiResponse,
        DocumentGetAffiliationAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/document/getAffiliationAdmin.html`,
          params: { documentAffiliationID: queryArg.documentAffiliationId },
        }),
        providesTags: ["document"],
      }),
      documentGetAdviceAdmin: build.query<
        DocumentGetAdviceAdminApiResponse,
        DocumentGetAdviceAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/document/getAdviceAdmin.html`,
          params: { documentID: queryArg.documentId },
        }),
        providesTags: ["document"],
      }),
      documentGetUserAllAdmin: build.query<
        DocumentGetUserAllAdminApiResponse,
        DocumentGetUserAllAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/document/getUserAllAdmin.html`,
          params: {
            documentID: queryArg.documentId,
            userDeviceTypeID: queryArg.userDeviceTypeId,
          },
        }),
        providesTags: ["document"],
      }),
      companyGetGuildAll: build.query<
        CompanyGetGuildAllApiResponse,
        CompanyGetGuildAllApiArg
      >({
        query: () => ({ url: `/company/getGuildAll.html` }),
        providesTags: ["company"],
      }),
      companyGetUserAllAdmin: build.query<
        CompanyGetUserAllAdminApiResponse,
        CompanyGetUserAllAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/company/getUserAllAdmin.html`,
          params: { id: queryArg.id },
        }),
        providesTags: ["company"],
      }),
      companyGetAll: build.query<CompanyGetAllApiResponse, CompanyGetAllApiArg>(
        {
          query: (queryArg) => ({
            url: `/company/getAll.html`,
            params: {
              offset: queryArg.offset,
              limit: queryArg.limit,
              search: queryArg.search,
            },
          }),
          providesTags: ["company"],
        }
      ),
      companyGetUserAll: build.query<
        CompanyGetUserAllApiResponse,
        CompanyGetUserAllApiArg
      >({
        query: (queryArg) => ({
          url: `/company/getUserAll.html`,
          params: {
            offset: queryArg.offset,
            limit: queryArg.limit,
            search: queryArg.search,
          },
        }),
        providesTags: ["company"],
      }),
      companyDeleteNavigationAdmin: build.mutation<
        CompanyDeleteNavigationAdminApiResponse,
        CompanyDeleteNavigationAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/company/deleteNavigationAdmin.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["company"],
      }),
      companyGetNavigationAdmin: build.query<
        CompanyGetNavigationAdminApiResponse,
        CompanyGetNavigationAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/company/getNavigationAdmin.html`,
          params: { id: queryArg.id },
        }),
        providesTags: ["company"],
      }),
      companyGetNavigationAllAdmin: build.query<
        CompanyGetNavigationAllAdminApiResponse,
        CompanyGetNavigationAllAdminApiArg
      >({
        query: () => ({ url: `/company/getNavigationAllAdmin.html` }),
        providesTags: ["company"],
      }),
      companySetNavigationAdmin: build.mutation<
        CompanySetNavigationAdminApiResponse,
        CompanySetNavigationAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/company/setNavigationAdmin.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["company"],
      }),
      companySetNavigationOrderAdmin: build.mutation<
        CompanySetNavigationOrderAdminApiResponse,
        CompanySetNavigationOrderAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/company/setNavigationOrderAdmin.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["company"],
      }),
      companyDeleteTypeAdmin: build.mutation<
        CompanyDeleteTypeAdminApiResponse,
        CompanyDeleteTypeAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/company/deleteTypeAdmin.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["company"],
      }),
      companyGetTypeAll: build.query<
        CompanyGetTypeAllApiResponse,
        CompanyGetTypeAllApiArg
      >({
        query: () => ({ url: `/company/getTypeAll.html` }),
        providesTags: ["company"],
      }),
      companySetTypeAdmin: build.mutation<
        CompanySetTypeAdminApiResponse,
        CompanySetTypeAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/company/setTypeAdmin.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["company"],
      }),
      companySetTypeOrderAdmin: build.mutation<
        CompanySetTypeOrderAdminApiResponse,
        CompanySetTypeOrderAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/company/setTypeOrderAdmin.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["company"],
      }),
      companySetGuild: build.mutation<
        CompanySetGuildApiResponse,
        CompanySetGuildApiArg
      >({
        query: (queryArg) => ({
          url: `/company/setGuild.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["company"],
      }),
      companyGetAllAdmin: build.query<
        CompanyGetAllAdminApiResponse,
        CompanyGetAllAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/company/getAllAdmin.html`,
          params: {
            search: queryArg.search,
            offset: queryArg.offset,
            limit: queryArg.limit,
          },
        }),
        providesTags: ["company"],
      }),
      companyDeleteAdmin: build.mutation<
        CompanyDeleteAdminApiResponse,
        CompanyDeleteAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/company/deleteAdmin.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["company"],
      }),
      companyDeleteChangesAdmin: build.mutation<
        CompanyDeleteChangesAdminApiResponse,
        CompanyDeleteChangesAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/company/deleteChangesAdmin.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["company"],
      }),
      companyGetTypeAllAdmin: build.query<
        CompanyGetTypeAllAdminApiResponse,
        CompanyGetTypeAllAdminApiArg
      >({
        query: () => ({ url: `/company/getTypeAllAdmin.html` }),
        providesTags: ["company"],
      }),
      companyGetTypeAdmin: build.query<
        CompanyGetTypeAdminApiResponse,
        CompanyGetTypeAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/company/getTypeAdmin.html`,
          params: { id: queryArg.id },
        }),
        providesTags: ["company"],
      }),
      companyGetAdmin: build.query<
        CompanyGetAdminApiResponse,
        CompanyGetAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/company/getAdmin.html`,
          params: { id: queryArg.id },
        }),
        providesTags: ["company"],
      }),
      companySetAdmin: build.mutation<
        CompanySetAdminApiResponse,
        CompanySetAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/company/setAdmin.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["company"],
      }),
      companyGetUsers: build.query<
        CompanyGetUsersApiResponse,
        CompanyGetUsersApiArg
      >({
        query: (queryArg) => ({
          url: `/company/getUsers.html`,
          params: {
            offset: queryArg.offset,
            limit: queryArg.limit,
            search: queryArg.search,
          },
        }),
        providesTags: ["company"],
      }),
      discussionGetAll: build.query<
        DiscussionGetAllApiResponse,
        DiscussionGetAllApiArg
      >({
        query: () => ({ url: `/discussion/getAll.html` }),
        providesTags: ["discussion"],
      }),
      discussionGet: build.query<DiscussionGetApiResponse, DiscussionGetApiArg>(
        {
          query: (queryArg) => ({
            url: `/discussion/get.html`,
            params: { id: queryArg.id },
          }),
          providesTags: ["discussion"],
        }
      ),
      discussionGetEdit: build.query<
        DiscussionGetEditApiResponse,
        DiscussionGetEditApiArg
      >({
        query: (queryArg) => ({
          url: `/discussion/getEdit.html`,
          params: { id: queryArg.id },
        }),
        providesTags: ["discussion"],
      }),
      discussionAdd: build.mutation<
        DiscussionAddApiResponse,
        DiscussionAddApiArg
      >({
        query: (queryArg) => ({
          url: `/discussion/add.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["discussion"],
      }),
      discussionEdit: build.mutation<
        DiscussionEditApiResponse,
        DiscussionEditApiArg
      >({
        query: (queryArg) => ({
          url: `/discussion/edit.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["discussion"],
      }),
      discussionAddMessage: build.mutation<
        DiscussionAddMessageApiResponse,
        DiscussionAddMessageApiArg
      >({
        query: (queryArg) => ({
          url: `/discussion/addMessage.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["discussion"],
      }),
      discussionDelete: build.mutation<
        DiscussionDeleteApiResponse,
        DiscussionDeleteApiArg
      >({
        query: (queryArg) => ({
          url: `/discussion/delete.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["discussion"],
      }),
      discussionLeave: build.mutation<
        DiscussionLeaveApiResponse,
        DiscussionLeaveApiArg
      >({
        query: (queryArg) => ({
          url: `/discussion/leave.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["discussion"],
      }),
      discussionSetMessagesRead: build.mutation<
        DiscussionSetMessagesReadApiResponse,
        DiscussionSetMessagesReadApiArg
      >({
        query: (queryArg) => ({
          url: `/discussion/setMessagesRead.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["discussion"],
      }),
      newsGetAll: build.query<NewsGetAllApiResponse, NewsGetAllApiArg>({
        query: (queryArg) => ({
          url: `/news/getAll.html`,
          params: {
            offset: queryArg.offset,
            limit: queryArg.limit,
            search: queryArg.search,
          },
        }),
        providesTags: ["news"],
      }),
      newsGet: build.query<NewsGetApiResponse, NewsGetApiArg>({
        query: (queryArg) => ({
          url: `/news/get.html`,
          params: { id: queryArg.id },
        }),
        providesTags: ["news"],
      }),
      newsGetCommentAllAdmin: build.query<
        NewsGetCommentAllAdminApiResponse,
        NewsGetCommentAllAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/news/getCommentAllAdmin.html`,
          params: { newsID: queryArg.newsId },
        }),
        providesTags: ["news"],
      }),
      newsAddComment: build.mutation<
        NewsAddCommentApiResponse,
        NewsAddCommentApiArg
      >({
        query: (queryArg) => ({
          url: `/news/addComment.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["news"],
      }),
      newsDeleteCommentAdmin: build.mutation<
        NewsDeleteCommentAdminApiResponse,
        NewsDeleteCommentAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/news/deleteCommentAdmin.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["news"],
      }),
      newsDeleteDocumentsAdmin: build.mutation<
        NewsDeleteDocumentsAdminApiResponse,
        NewsDeleteDocumentsAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/news/deleteDocumentsAdmin.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["news"],
      }),
      newsDeleteAdmin: build.mutation<
        NewsDeleteAdminApiResponse,
        NewsDeleteAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/news/deleteAdmin.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["news"],
      }),
      newsDeleteAdviceAdmin: build.mutation<
        NewsDeleteAdviceAdminApiResponse,
        NewsDeleteAdviceAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/news/deleteAdviceAdmin.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["news"],
      }),
      newsGetUserAllAdmin: build.query<
        NewsGetUserAllAdminApiResponse,
        NewsGetUserAllAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/news/getUserAllAdmin.html`,
          params: {
            newsID: queryArg.newsId,
            userDeviceTypeID: queryArg.userDeviceTypeId,
          },
        }),
        providesTags: ["news"],
      }),
      newsSetAdmin: build.mutation<NewsSetAdminApiResponse, NewsSetAdminApiArg>(
        {
          query: (queryArg) => ({
            url: `/news/setAdmin.html`,
            method: "POST",
            body: queryArg.body,
          }),
          invalidatesTags: ["news"],
        }
      ),
      newsGetDocuments: build.query<
        NewsGetDocumentsApiResponse,
        NewsGetDocumentsApiArg
      >({
        query: (queryArg) => ({
          url: `/news/getDocuments.html`,
          params: { newsID: queryArg.newsId },
        }),
        providesTags: ["news"],
      }),
      newsGetAdviceAdmin: build.query<
        NewsGetAdviceAdminApiResponse,
        NewsGetAdviceAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/news/getAdviceAdmin.html`,
          params: { newsID: queryArg.newsId },
        }),
        providesTags: ["news"],
      }),
      newsAutodeleteNews: build.mutation<
        NewsAutodeleteNewsApiResponse,
        NewsAutodeleteNewsApiArg
      >({
        query: (queryArg) => ({
          url: `/news/autodeleteNews.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["news"],
      }),
      propertyXx: build.query<PropertyXxApiResponse, PropertyXxApiArg>({
        query: () => ({ url: `/property/xx.html` }),
        providesTags: ["property"],
      }),
      mailSetTemplateAdmin: build.mutation<
        MailSetTemplateAdminApiResponse,
        MailSetTemplateAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/mail/setTemplateAdmin.html`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["mail"],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as deinVerbandApi };
export type StatisticGetProductivityApiResponse =
  /** status 200 Success */ GetProductivityResponse;
export type StatisticGetProductivityApiArg = void;
export type StatisticGetCoreApiResponse =
  /** status 200 Success */ GetCoreResponse;
export type StatisticGetCoreApiArg = void;
export type AssociationGetStyleApiResponse =
  /** status 200 OK */ GetStyleResponse;
export type AssociationGetStyleApiArg = {
  associationId: number;
};
export type AssociationGetStyleByDomainApiResponse =
  /** status 200 OK */ GetStyleByDomainResponse;
export type AssociationGetStyleByDomainApiArg = {
  domain: string;
};
export type AssociationGetAllApiResponse =
  /** status 200 Success */ GetAllResponse;
export type AssociationGetAllApiArg = {
  /** Search term */
  search?: string;
  /** Limit of results */
  limit?: number;
  /** Offset of results */
  offset?: number;
};
export type AssociationGetApiResponse =
  /** status 200 Success */ GetAssociationResponse;
export type AssociationGetApiArg = {
  /** The id of the association */
  id?: number;
};
export type AssociationSetApiResponse =
  /** status 200 OK */ SetAssociationResponse;
export type AssociationSetApiArg = {
  body: {
    associationID?: string;
    associationName?: string;
    associationAddress?: string;
    associationZip?: string;
    associationCity?: string;
    logoFile?: string;
    notificationAndroidKey?: string;
    iosFile?: string;
    iosAppID?: string;
    iosDevelopment?: string;
    iosKeyID?: string;
    primaryColor?: string;
    secondaryColor?: string;
    buttonColor?: string;
    newDomains?: {
      name?: string;
      https?: boolean;
    }[];
    oldDomains?: string[];
  };
};
export type AssociationDeleteApiResponse =
  /** status 200 Success */ DeleteResponse;
export type AssociationDeleteApiArg = {
  body: {
    id?: number;
  };
};
export type AssociationSetDomainAdminApiResponse =
  /** status 200 Success */ SetDomainAdminResponse;
export type AssociationSetDomainAdminApiArg = {
  body: {
    id?: string;
    https?: boolean;
  };
};
export type AssociationSetUseTemplateAdminApiResponse =
  /** status 200 Success */ SetUseTemplateAdminResponse;
export type AssociationSetUseTemplateAdminApiArg = {
  body: {
    option?: string;
  };
};
export type AssociationSetTemplateFileAdminApiResponse =
  /** status 200 Success */ SetTemplateFileAdminResponse;
export type AssociationSetTemplateFileAdminApiArg = {
  body: {
    templateFile?: string;
  };
};
export type AssociationDeleteImageAdminApiResponse =
  /** status 200 Success */ DeleteImageAdminResponse;
export type AssociationDeleteImageAdminApiArg = {
  body: {
    id?: number;
  };
};
export type AssociationGetAdminUserAllApiResponse =
  /** status 200 OK */ GetAdminUserAllResponse;
export type AssociationGetAdminUserAllApiArg = {
  id: number;
};
export type AssociationGetImagesAllAdminApiResponse =
  /** status 200 Success */ GetImagesAllAdminResponse;
export type AssociationGetImagesAllAdminApiArg = void;
export type AssociationAddImageAdminApiResponse =
  /** status 200 OK */ AddImageAdminResponse;
export type AssociationAddImageAdminApiArg = {
  body: {
    name?: string;
    file?: string;
  };
};
export type AssociationSetLetterAdminApiResponse =
  /** status 200 OK */ SetLetterAdminResponse;
export type AssociationSetLetterAdminApiArg = {
  body: {
    id?: string;
    title?: string;
    header?: string;
    footer?: string;
    letterFile?: string;
    useTemplate?: number;
  };
};
export type AssociationGetLetterAllAdminApiResponse =
  /** status 200 Success */ GetLetterAllAdminResponse;
export type AssociationGetLetterAllAdminApiArg = void;
export type AssociationGetLetterAdminApiResponse =
  /** status 200 Success */ GetLetterAdminResponse;
export type AssociationGetLetterAdminApiArg = {
  id: number;
};
export type AssociationDeleteAssociationLetterAdminApiResponse =
  /** status 200 Success */ DeleteAssociationLetterAdminResponse;
export type AssociationDeleteAssociationLetterAdminApiArg = {
  body: {
    id?: number;
  };
};
export type AssociationSetGeneralApiResponse =
  /** status 200 OK */ SetGeneralResponse;
export type AssociationSetGeneralApiArg = {
  body: {
    sharemode?: string;
  };
};
export type AssociationSetSettingsModeApiResponse =
  /** status 200 OK */ SetSettingsModeResponse;
export type AssociationSetSettingsModeApiArg = {
  body: {
    mode?: string;
  };
};
export type AssociationSetGuildAdminApiResponse =
  /** status 200 OK */ SetGuildAdminResponse;
export type AssociationSetGuildAdminApiArg = {
  body: {
    associations?: string[];
    guildID?: string;
    accepted?: number;
  };
};
export type AssociationDeleteGuildAdminApiResponse =
  /** status 200 Success */ DeleteGuildAdminResponse;
export type AssociationDeleteGuildAdminApiArg = {
  body: {
    associationID?: string;
    guildID?: string;
  };
};
export type AssociationGetGuildAdminApiResponse =
  /** status 200 Success */ GetGuildAdminResponse;
export type AssociationGetGuildAdminApiArg = {
  guildId: number;
};
export type AssociationGetGuildReceivedAllAdminApiResponse =
  /** status 200 OK */ GetGuildReceivedAllAdminResponse;
export type AssociationGetGuildReceivedAllAdminApiArg = void;
export type AssociationGetGuildSharedAdminAllApiResponse =
  /** status 200 OK */ GetGuildSharedAdminAllResponse;
export type AssociationGetGuildSharedAdminAllApiArg = void;
export type AssociationGetErrorsCountApiResponse =
  /** status 200 Success */ GetErrorsCountResponse;
export type AssociationGetErrorsCountApiArg = void;
export type AssociationGetErrorsApiResponse =
  /** status 200 OK */ GetErrorsResponse;
export type AssociationGetErrorsApiArg = void;
export type AssociationSetErrorResendApiResponse =
  /** status 200 OK */ SetErrorResendResponse;
export type AssociationSetErrorResendApiArg = {
  body: {
    notificationID?: string;
  };
};
export type AssociationSetErrorDeleteApiResponse =
  /** status 200 OK */ SetErrorDeleteResponse;
export type AssociationSetErrorDeleteApiArg = {
  body: {
    notificationID?: string;
  };
};
export type LinkGetAllApiResponse = /** status 200 OK */ GetAllResponse;
export type LinkGetAllApiArg = void;
export type LinkGetAllAdminApiResponse =
  /** status 200 Success */ GetAllAdminResponse;
export type LinkGetAllAdminApiArg = {
  /** Offset of the result set */
  offset?: number;
  /** Limit of the result set */
  limit?: number;
  /** Search query */
  search?: string;
  /** Guild ID */
  guildId?: number;
  /** Company type ID */
  companyTypeId?: number;
};
export type LinkGetListAdminApiResponse =
  /** status 200 Success */ GetListAdminResponse;
export type LinkGetListAdminApiArg = void;
export type LinkGetAdviceAllAdminApiResponse =
  /** status 200 Success */ GetAdviceAllAdminResponse;
export type LinkGetAdviceAllAdminApiArg = {
  /** Guild ID */
  guildId?: number;
  /** Offset */
  offset?: number;
  /** Limit */
  limit?: number;
  /** Search */
  search?: string;
};
export type LinkDeleteAdminApiResponse =
  /** status 200 Success */ DeleteAdminResponse;
export type LinkDeleteAdminApiArg = {
  body: {
    linkID?: number;
    linkIDs?: number[];
  };
};
export type LinkDeleteAdviceAdminApiResponse =
  /** status 200 Success */ DeleteAdviceAdminResponse;
export type LinkDeleteAdviceAdminApiArg = {
  body: {
    linkID?: string;
    allData?: string;
  };
};
export type LinkSetGroupAdminApiResponse =
  /** status 200 Success */ SetGroupAdminResponse;
export type LinkSetGroupAdminApiArg = {
  body: {
    linkGroupID?: number;
  };
};
export type LinkDeleteGroupAdminApiResponse =
  /** status 200 OK */ DeleteGroupAdminResponse;
export type LinkDeleteGroupAdminApiArg = {
  body: {
    linkGroupID?: string;
  };
};
export type LinkGetGroupAllAdminApiResponse =
  /** status 200 Success */ GetGroupAllAdminResponse;
export type LinkGetGroupAllAdminApiArg = void;
export type LinkSetAdminApiResponse = /** status 200 OK */ SetAdminResponse;
export type LinkSetAdminApiArg = {
  body: {
    linkID?: number;
    url?: string;
    shares?: string[];
    name?: string;
    groupID?: number;
    shareGuilds?: number[];
  };
};
export type LinkGetAdminApiResponse =
  /** status 200 Success */ GetAdminResponse;
export type LinkGetAdminApiArg = {
  linkId: number;
};
export type LinkGetAdviceAdminApiResponse =
  /** status 200 Success */ GetAdviceAdminResponse;
export type LinkGetAdviceAdminApiArg = {
  linkId: number;
};
export type LinkGetGroupAdminApiResponse =
  /** status 200 Success */ GetGroupAdminResponse;
export type LinkGetGroupAdminApiArg = {
  linkGroupId: number;
};
export type PropertyGetDataAllAdminApiResponse =
  /** status 200 Success */ GetDataAllAdminResponse;
export type PropertyGetDataAllAdminApiArg = {
  companyId: number;
};
export type PropertyGetDataAllApiResponse =
  /** status 200 Success */ GetDataAllResponse;
export type PropertyGetDataAllApiArg = void;
export type PropertySetDataAllApiResponse =
  /** status 200 Success */ SetDataAllResponse;
export type PropertySetDataAllApiArg = {
  body: {
    data?: string;
  };
};
export type PropertyGetTabDataAdminApiResponse =
  /** status 200 Success */ GetTabDataAdminResponse;
export type PropertyGetTabDataAdminApiArg = {
  id: number;
  companyId?: number;
};
export type PropertySetFieldOptionAdminApiResponse =
  /** status 200 OK */ SetFieldOptionAdminResponse;
export type PropertySetFieldOptionAdminApiArg = {
  body: {
    names?: string[];
    fieldID?: string;
    optionID?: string;
  };
};
export type PropertySetFieldOptionPropertyAdminApiResponse =
  /** status 200 OK */ SetFieldOptionPropertyAdminResponse;
export type PropertySetFieldOptionPropertyAdminApiArg = {
  body: {
    options?: {
      fieldID?: number;
      optionID?: number;
      propertyID?: number;
      value?: string;
    }[];
    fieldID?: number;
    companyID?: number;
    create?: boolean;
  };
};
export type PropertySetFieldAdminApiResponse =
  /** status 200 OK */ SetFieldAdminResponse;
export type PropertySetFieldAdminApiArg = {
  body: {
    name?: string;
    fieldID?: number;
    fieldRequestable?: boolean;
    blockID?: number;
    typeID?: number;
    companyID?: number;
    resetOptions?: boolean;
  };
};
export type PropertySetValueAdminApiResponse =
  /** status 200 Success */ SetValueAdminResponse;
export type PropertySetValueAdminApiArg = {
  body: {
    value?: string;
    fieldID?: number;
    optionID?: number;
    propertyID?: number;
    companyID?: number;
  };
};
export type PropertyGetFieldAdminApiResponse =
  /** status 200 Success */ GetFieldAdminResponse;
export type PropertyGetFieldAdminApiArg = {
  id: number;
  companyId: number;
};
export type PropertyGetFieldTypeAdminAllApiResponse =
  /** status 200 Success */ GetFieldTypeAdminAllResponse;
export type PropertyGetFieldTypeAdminAllApiArg = void;
export type PropertyGetTabAdminAllApiResponse =
  /** status 200 Success */ GetTabAdminAllResponse;
export type PropertyGetTabAdminAllApiArg = void;
export type PropertyGetTabTypeAllAdminApiResponse =
  /** status 200 Success */ GetTabTypeAllAdminResponse;
export type PropertyGetTabTypeAllAdminApiArg = void;
export type PropertyGetTabAdminApiResponse =
  /** status 200 Success */ GetTabAdminResponse;
export type PropertyGetTabAdminApiArg = {
  id: number;
};
export type PropertySetTabAdminApiResponse =
  /** status 200 Success */ SetTabAdminResponse;
export type PropertySetTabAdminApiArg = {
  body: {
    name?: string;
    id?: number;
    typeID?: number;
  };
};
export type PropertyDeleteTabAdminApiResponse =
  /** status 200 Success */ DeleteTabAdminResponse;
export type PropertyDeleteTabAdminApiArg = {
  body: {
    id?: number;
  };
};
export type PropertyDeleteFieldAdminApiResponse =
  /** status 200 Success */ DeleteFieldAdminResponse;
export type PropertyDeleteFieldAdminApiArg = {
  body: {
    id?: number;
  };
};
export type PropertyDeleteOptionAdminApiResponse =
  /** status 200 Success */ DeleteOptionAdminResponse;
export type PropertyDeleteOptionAdminApiArg = {
  body: {
    id?: number;
  };
};
export type PropertySetTabOrderAdminApiResponse =
  /** status 200 Success */ SetTabOrderAdminResponse;
export type PropertySetTabOrderAdminApiArg = {
  body: {
    data?: string[];
  };
};
export type PropertySetFieldOrderAdminApiResponse =
  /** status 200 OK */ SetFieldOrderAdminResponse;
export type PropertySetFieldOrderAdminApiArg = {
  body: {
    data?: string[];
  };
};
export type PropertyGetTabBlockAdminAllApiResponse =
  /** status 200 Success */ GetTabBlockAdminAllResponse;
export type PropertyGetTabBlockAdminAllApiArg = {
  tabId: number;
};
export type PropertyGetTabBlockAdminApiResponse =
  /** status 200 Success */ GetTabBlockAdminResponse;
export type PropertyGetTabBlockAdminApiArg = {
  id: number;
};
export type PropertySetTabBlockAdminApiResponse =
  /** status 200 Success */ SetTabBlockAdminResponse;
export type PropertySetTabBlockAdminApiArg = {
  body: {
    name?: string;
    tabID?: string;
    id?: string;
  };
};
export type PropertyAddTabDocumentGroupAdminApiResponse =
  /** status 200 Success */ AddTabDocumentGroupAdminResponse;
export type PropertyAddTabDocumentGroupAdminApiArg = {
  body: {
    id?: number;
    name?: string;
  };
};
export type PropertyGetTabDocumentGroupsAdminApiResponse =
  /** status 200 Success */ GetTabDocumentGroupsAdminResponse;
export type PropertyGetTabDocumentGroupsAdminApiArg = {
  tabId: number;
};
export type PropertyDeleteTabBlockAdminApiResponse =
  /** status 200 Success */ DeleteTabBlockAdminResponse;
export type PropertyDeleteTabBlockAdminApiArg = {
  body: {
    id?: number;
  };
};
export type PropertySetTabBlockOrderAdminApiResponse =
  /** status 200 OK */ SetTabBlockOrderAdminResponse;
export type PropertySetTabBlockOrderAdminApiArg = {
  body: {
    data?: string[];
  };
};
export type PropertyGetTabDocumentAllAdminApiResponse =
  /** status 200 Success */ GetTabDocumentAllAdminResponse;
export type PropertyGetTabDocumentAllAdminApiArg = {
  tabId: number;
  companyId?: number;
};
export type PropertyGetTabTimelineAllAdminApiResponse =
  /** status 200 OK */ GetTabTimelineAllAdminResponse;
export type PropertyGetTabTimelineAllAdminApiArg = void;
export type PropertySetTabDocumentAdminApiResponse =
  /** status 200 Success */ SetTabDocumentAdminResponse;
export type PropertySetTabDocumentAdminApiArg = {
  body: {
    file?: string;
    id?: string;
    tabID?: string;
    companyID?: string;
    name?: string;
    group?: string;
  };
};
export type PropertySetTabTimelineAdminApiResponse =
  /** status 200 Success */ SetTabTimelineAdminResponse;
export type PropertySetTabTimelineAdminApiArg = {
  body: {
    text?: string;
    id?: number;
    tabID?: string;
    files?: {
      name?: string;
      file?: string;
    }[];
    companyID?: number;
  };
};
export type PropertyDeleteTabTimelineAdminApiResponse =
  /** status 200 Success */ DeleteTabTimelineAdminResponse;
export type PropertyDeleteTabTimelineAdminApiArg = {
  body: {
    id?: number;
  };
};
export type PropertyDeleteTabDocumentAdminApiResponse =
  /** status 200 Success */ DeleteTabDocumentAdminResponse;
export type PropertyDeleteTabDocumentAdminApiArg = {
  body: {
    id?: number;
  };
};
export type PropertyDeleteTabTimelineFileAdminApiResponse =
  /** status 200 Success */ DeleteTabTimelineFileAdminResponse;
export type PropertyDeleteTabTimelineFileAdminApiArg = {
  body: {
    id?: number;
  };
};
export type PropertyGetExportDataAdminApiResponse =
  /** status 200 Success */ GetExportDataAdminResponse;
export type PropertyGetExportDataAdminApiArg = void;
export type PropertyAddExportPresetItemAdminApiResponse =
  /** status 200 Success */ AddExportPresetItemAdminResponse;
export type PropertyAddExportPresetItemAdminApiArg = {
  body: {
    items?: string[];
    presetID?: string;
  };
};
export type PropertyAddExportPresetAdminApiResponse =
  /** status 200 Success */ AddExportPresetAdminResponse;
export type PropertyAddExportPresetAdminApiArg = {
  body: {
    name?: string;
  };
};
export type PropertyGetExportPresetAdminApiResponse =
  /** status 200 Successful response */ GetExportPresetAdminResponse;
export type PropertyGetExportPresetAdminApiArg = void;
export type UserLoginApiResponse = /** status 200 OK */ LoginResponse;
export type UserLoginApiArg = {
  body: {
    mail?: string;
    password?: string;
    associationID?: number;
  };
};
export type UserIsValidApikeyApiResponse =
  /** status 200 OK */ IsValidApikeyResponse;
export type UserIsValidApikeyApiArg = {
  apikey: string;
};
export type UserGetNavigationApiResponse =
  /** status 200 OK */ GetNavigationResponse;
export type UserGetNavigationApiArg = void;
export type UserGetNavigationItemApiResponse =
  /** status 200 Success */ GetNavigationItemResponse;
export type UserGetNavigationItemApiArg = {
  id: number;
};
export type UserSetNavigationCountEmptyApiResponse =
  /** status 200 OK */ SetNavigationCountEmptyResponse;
export type UserSetNavigationCountEmptyApiArg = {
  body: {
    result?: boolean;
  };
};
export type UserGetDetailsApiResponse =
  /** status 200 Success */ GetDetailsResponse;
export type UserGetDetailsApiArg = void;
export type UserGetDeviceAdminApiResponse =
  /** status 200 Success */ GetDeviceAdminResponse;
export type UserGetDeviceAdminApiArg = {
  id: number;
};
export type UserDeleteDeviceApiResponse =
  /** status 200 Success */ DeleteDeviceResponse;
export type UserDeleteDeviceApiArg = {
  body: {
    devicetoken?: string;
    deviceos?: string;
  };
};
export type UserAddDeviceApiResponse =
  /** status 200 Success */ AddDeviceResponse;
export type UserAddDeviceApiArg = {
  body: {
    deviceos?: string;
    devicetoken?: string;
    devicecordova?: string;
    devicename?: string;
    devicesystem?: string;
    deviceuuid?: string;
    deviceversion?: string;
    devicemanufacturer?: string;
    deviceheight?: string;
    devicewidth?: string;
    devicelanguage?: string;
  };
};
export type UserSendLoginAdminApiResponse =
  /** status 200 Success */ SendLoginAdminResponse;
export type UserSendLoginAdminApiArg = {
  body: {
    userID?: string;
  };
};
export type UserGetAllAdminApiResponse =
  /** status 200 Success */ GetAllAdminResponse;
export type UserGetAllAdminApiArg = {
  /** User ID */
  id?: number;
  /** Offset */
  offset?: number;
  /** Search */
  search?: string;
  /** Limit */
  limit?: number;
};
export type UserGetAdminAllAdminApiResponse =
  /** status 200 Success */ GetAdminAllAdminResponse;
export type UserGetAdminAllAdminApiArg = void;
export type UserGetAdminApiResponse =
  /** status 200 Success */ GetAdminResponse;
export type UserGetAdminApiArg = {
  id: number;
};
export type UserSetAdminApiResponse = /** status 200 OK */ SetAdminResponse;
export type UserSetAdminApiArg = {
  body: {
    userID?: string;
    companyID?: number;
    userGender?: string;
    userFirstname?: string;
    userLastname?: string;
    userMail?: string;
    userAdmin?: number;
    userSharesRead?: string[][];
    userSharesWrite?: string;
  };
};
export type UserDeleteUserAdminApiResponse =
  /** status 200 Success */ DeleteUserAdminResponse;
export type UserDeleteUserAdminApiArg = {
  body: {
    id?: number;
  };
};
export type UserAddGuildAdminApiResponse =
  /** status 200 Success */ AddGuildAdminResponse;
export type UserAddGuildAdminApiArg = {
  body: {
    userID?: number;
    guildID?: number;
    companyTypeID?: number;
  };
};
export type UserDeleteAdminApiResponse =
  /** status 200 Success */ DeleteAdminResponse;
export type UserDeleteAdminApiArg = {
  body: {
    id?: number;
  };
};
export type UserDeleteGuildAdminApiResponse =
  /** status 200 Success */ DeleteGuildAdminResponse;
export type UserDeleteGuildAdminApiArg = {
  body: {
    id?: number;
  };
};
export type UserSetPasswordRequestApiResponse =
  /** status 200 OK */ SetPasswordRequestResponse;
export type UserSetPasswordRequestApiArg = {
  body: {
    associationID?: number;
    mail?: string;
  };
};
export type UserResetPasswordApiResponse =
  /** status 200 OK */ ResetPasswordResponse;
export type UserResetPasswordApiArg = {
  body: {
    key?: string;
    password?: string;
  };
};
export type UserSetChangesApiResponse = /** status 200 OK */ SetChangesResponse;
export type UserSetChangesApiArg = {
  body: {
    street?: string;
    zip?: string;
    location?: string;
    telephone1?: string;
    telephone2?: string;
    mobile?: string;
    mail?: string;
    fax?: string;
    web?: string;
    userGender?: number;
    userFirstname?: string;
    userLastname?: string;
    userMail?: string;
    userOldPassword?: string;
    userNewPassword?: string;
    settingsMode?: number;
    additional?: string;
  };
};
export type UserGetChangesApiResponse = /** status 200 OK */ GetChangesResponse;
export type UserGetChangesApiArg = void;
export type UserGetChangesAllAdminApiResponse =
  /** status 200 OK */ GetChangesAllAdminResponse;
export type UserGetChangesAllAdminApiArg = void;
export type UserSetChangeAdminApiResponse =
  /** status 200 Success */ SetChangeAdminResponse;
export type UserSetChangeAdminApiArg = {
  body: {
    id?: string;
  };
};
export type UserDeleteChangesAdminApiResponse =
  /** status 200 Success */ DeleteChangesAdminResponse;
export type UserDeleteChangesAdminApiArg = {
  body: {
    id?: string;
  };
};
export type UserGetByActivationKeyApiResponse =
  /** status 200 Success */ GetByActivationKeyResponse;
export type UserGetByActivationKeyApiArg = {
  key: string;
};
export type MailGetAccountAllAdminApiResponse =
  /** status 200 Success */ GetAccountAllAdminResponse;
export type MailGetAccountAllAdminApiArg = void;
export type MailGetAccountAdminApiResponse =
  /** status 200 Success */ GetAccountAdminResponse;
export type MailGetAccountAdminApiArg = {
  id: number;
};
export type MailGetAccountTypeAllAdminApiResponse =
  /** status 200 Success */ GetAccountTypeAllAdminResponse;
export type MailGetAccountTypeAllAdminApiArg = void;
export type MailDeleteAccountAdminApiResponse =
  /** status 200 Success */ DeleteAccountAdminResponse;
export type MailDeleteAccountAdminApiArg = {
  body: {
    id?: number;
  };
};
export type MailSetAccountActiveAdminApiResponse =
  /** status 200 OK */ SetAccountActiveAdminResponse;
export type MailSetAccountActiveAdminApiArg = {
  body: {
    id?: number;
    active?: number;
  };
};
export type MailSetSignatureActiveAdminApiResponse =
  /** status 200 OK */ SetSignatureActiveAdminResponse;
export type MailSetSignatureActiveAdminApiArg = {
  body: {
    id?: number;
    active?: number;
  };
};
export type MailAddSignatureAdminApiResponse =
  /** status 200 Success */ AddSignatureAdminResponse;
export type MailAddSignatureAdminApiArg = {
  body: {
    name?: string;
    signature?: string;
  };
};
export type MailGetSignatureAllAdminApiResponse =
  /** status 200 Success */ GetSignatureAllAdminResponse;
export type MailGetSignatureAllAdminApiArg = void;
export type MailGetSignatureAdminApiResponse =
  /** status 200 Success */ GetSignatureAdminResponse;
export type MailGetSignatureAdminApiArg = {
  id: number;
};
export type MailEditSignatureAdminApiResponse =
  /** status 200 OK */ EditSignatureAdminResponse;
export type MailEditSignatureAdminApiArg = {
  body: {
    id?: number;
    name?: string;
    signature?: string;
  };
};
export type MailDeleteSignatureAdminApiResponse =
  /** status 200 Success */ DeleteSignatureAdminResponse;
export type MailDeleteSignatureAdminApiArg = {
  body: {
    id?: number;
  };
};
export type MailGetMailContentAdminApiResponse =
  /** status 200 Success */ GetMailContentAdminResponse;
export type MailGetMailContentAdminApiArg = void;
export type MailSetMailAdminApiResponse =
  /** status 200 Success */ SetMailAdminResponse;
export type MailSetMailAdminApiArg = {
  body: {
    id?: number;
    body?: string;
  };
};
export type MailSetAccountAdminApiResponse =
  /** status 200 Success */ SetAccountAdminResponse;
export type MailSetAccountAdminApiArg = {
  body: {
    id?: string;
    type?: number;
    server?: string;
    user?: string;
    password?: string;
    port?: string;
    ssl?: string;
    mail?: string;
    name?: string;
  };
};
export type MailSetAccountAdminOffice365ApiResponse =
  /** status 200 Success */ SetAccountAdminOffice365Response;
export type MailSetAccountAdminOffice365ApiArg = {
  body: {
    type?: number;
    code?: string;
  };
};
export type MailGetTemplatesAllAdminApiResponse =
  /** status 200 OK */ GetTemplatesAllAdminResponse;
export type MailGetTemplatesAllAdminApiArg = void;
export type NotificationGetTemplatesAllApiResponse =
  /** status 200 OK */ GetTemplatesAllResponse;
export type NotificationGetTemplatesAllApiArg = void;
export type NotificationGetTemplateApiResponse =
  /** status 200 Success */ GetTemplateResponse;
export type NotificationGetTemplateApiArg = {
  id: number;
};
export type NotificationDeleteTemplateApiResponse =
  /** status 200 Success */ DeleteTemplateResponse;
export type NotificationDeleteTemplateApiArg = {
  body: {
    id?: number;
  };
};
export type NotificationAddTemplateEmptyApiResponse =
  /** status 200 OK */ AddTemplateEmptyResponse;
export type NotificationAddTemplateEmptyApiArg = {
  body: {
    result?: boolean;
  };
};
export type NotificationSetTemplateApiResponse =
  /** status 200 Success */ SetTemplateResponse;
export type NotificationSetTemplateApiArg = {
  body: {
    id?: number;
    name?: string;
    template?: string;
    sender?: string;
    nltobr?: number;
  };
};
export type NotificationAddTestApiResponse =
  /** status 200 Success */ AddTestResponse;
export type NotificationAddTestApiArg = {
  body: {
    id?: number;
  };
};
export type NotificationAutoDeleteApiResponse =
  /** status 200 Success */ AutoDeleteResponse;
export type NotificationAutoDeleteApiArg = {
  body: {};
};
export type NotificationGenerateEpostFilesApiResponse =
  /** status 200 Success */ GenerateEpostFilesResponse;
export type NotificationGenerateEpostFilesApiArg = void;
export type NotificationConvertPdfFileApiResponse =
  /** status 200 Success */ ConvertPdfFileResponse;
export type NotificationConvertPdfFileApiArg = {
  body: {
    file?: string;
  };
};
export type NotificationMergePdfFilesApiResponse =
  /** status 200 Success */ MergePdfFilesResponse;
export type NotificationMergePdfFilesApiArg = {
  body: {
    fileName?: string;
    fileArray?: string[];
  };
};
export type NotificationResendApiResponse =
  /** status 200 Success */ ResendResponse;
export type NotificationResendApiArg = {
  body: {};
};
export type NotificationGetReceiverCountsApiResponse =
  /** status 200 Success */ GetReceiverCountsResponse;
export type NotificationGetReceiverCountsApiArg = {
  shares: number[][];
  shareApp: boolean;
  shareMail: boolean;
  shareEpost: boolean;
};
export type NotificationSetReceiverCountsApiResponse =
  /** status 200 Success */ SetReceiverCountsResponse;
export type NotificationSetReceiverCountsApiArg = {
  body: {
    shares?: number[][];
    shareApp?: boolean;
    shareMail?: boolean;
    shareEpost?: boolean;
  };
};
export type NotificationGetImageInfoApiResponse =
  /** status 200 Success */ GetImageInfoResponse;
export type NotificationGetImageInfoApiArg = {
  identifier: string;
};
export type NotificationGetTypesApiResponse =
  /** status 200 OK */ GetTypesResponse;
export type NotificationGetTypesApiArg = void;
export type NotificationSetTypesApiResponse =
  /** status 200 OK */ SetTypesResponse;
export type NotificationSetTypesApiArg = {
  body: {
    notificationTypeID?: number;
    notificationTypeName?: string;
  };
};
export type NotificationGetUserAllAdminApiResponse =
  /** status 200 Success */ GetUserAllAdminResponse;
export type NotificationGetUserAllAdminApiArg = void;
export type NotificationSetUserAllAdminApiResponse =
  /** status 200 Success */ SetUserAllAdminResponse;
export type NotificationSetUserAllAdminApiArg = {
  body: {
    notificationTypeID?: number;
    notificationTypeName?: string;
    isSelected?: boolean;
  };
};
export type NotificationDeleteUserAdminApiResponse =
  /** status 200 Success */ DeleteUserAdminResponse;
export type NotificationDeleteUserAdminApiArg = {
  body: {
    userID?: string;
    typeID?: string;
  };
};
export type NotificationAddUserAdminApiResponse =
  /** status 200 Success */ AddUserAdminResponse;
export type NotificationAddUserAdminApiArg = {
  body: {
    userID?: string;
    typeID?: string;
  };
};
export type NotificationAddTestMailApiResponse =
  /** status 200 OK */ AddTestMailResponse;
export type NotificationAddTestMailApiArg = {
  body: {
    host?: string;
    user?: string;
    password?: string;
    ssl?: number;
    port?: number;
    fromMail?: string;
    fromName?: string;
  };
};
export type NotificationRefreshAccessTokensApiResponse =
  /** status 200 OK */ RefreshAccessTokensResponse;
export type NotificationRefreshAccessTokensApiArg = {
  body: {
    aerefreshtoken?: string;
  };
};
export type ExceptionExceptionApiResponse =
  /** status 200 OK */ ExceptionResponse;
export type ExceptionExceptionApiArg = {
  body: {
    exception?: string;
  };
};
export type GuildGetAllAdminApiResponse =
  /** status 200 Success */ GetAllAdminResponse;
export type GuildGetAllAdminApiArg = void;
export type GuildGetAdminAllAdminApiResponse =
  /** status 200 Success */ GetAdminAllAdminResponse;
export type GuildGetAdminAllAdminApiArg = {
  /** User ID */
  userId?: number;
};
export type GuildGetAdminApiResponse =
  /** status 200 Success */ GetAdminResponse;
export type GuildGetAdminApiArg = {
  id: number;
};
export type GuildSetAdminApiResponse =
  /** status 200 Success */ SetAdminResponse;
export type GuildSetAdminApiArg = {
  body: {
    id?: number;
    parentID?: number;
    name?: string;
  };
};
export type GuildDeleteAdminApiResponse =
  /** status 200 Success */ DeleteAdminResponse;
export type GuildDeleteAdminApiArg = {
  body: {
    id?: number;
  };
};
export type GuildSetNotifictionTemplateApiResponse =
  /** status 200 Success */ SetNotifictionTemplateResponse;
export type GuildSetNotifictionTemplateApiArg = {
  body: {
    templateID?: string;
    guildID?: string;
  };
};
export type GuildGetNotificationTemplateApiResponse =
  /** status 200 Success */ GetNotificationTemplateResponse;
export type GuildGetNotificationTemplateApiArg = {
  guildId: number;
};
export type GuildGetUserAllAdminApiResponse =
  /** status 200 Success */ GetUserAllAdminResponse;
export type GuildGetUserAllAdminApiArg = void;
export type GuildAddAdminAdminApiResponse =
  /** status 200 Success */ AddAdminAdminResponse;
export type GuildAddAdminAdminApiArg = {
  body: {
    id?: number;
    userID?: number;
  };
};
export type GuildDeleteAdminAdminApiResponse =
  /** status 200 Success */ DeleteAdminAdminResponse;
export type GuildDeleteAdminAdminApiArg = {
  body: {
    userID?: number;
  };
};
export type GeneralGetChangelogApiResponse =
  /** status 200 Success */ GetChangelogResponse;
export type GeneralGetChangelogApiArg = void;
export type AppointmentGetAllApiResponse =
  /** status 200 Success */ GetAllResponse;
export type AppointmentGetAllApiArg = void;
export type AppointmentSetAppointmentApiResponse =
  /** status 200 Success */ SetAppointmentResponse;
export type AppointmentSetAppointmentApiArg = {
  body: {
    appointmentName?: string;
    appointmentDescription?: string;
    appointmentDate?: string;
    appointmentDateEnd?: string;
    appointmentAddress?: string;
    appointmentLocation?: string;
    appointmentZip?: string;
    appointmentLat?: string;
    appointmentLng?: string;
    appointmentResponseAllow?: number;
    appointmentResponseMemberNumberAllow?: number;
    appointmentResponseTextAllow?: number;
    appointmentResponsePlaceholder?: string;
    appointmentMemberView?: number;
    appointmentGuilds?: string[];
  };
};
export type AppointmentGetAdviceAllAdminApiResponse =
  /** status 200 Success */ GetAdviceAllAdminResponse;
export type AppointmentGetAdviceAllAdminApiArg = {
  /** Offset */
  offset?: number;
  /** Limit */
  limit?: number;
  /** Guild ID */
  guildId?: number;
  /** Search */
  search?: string;
};
export type AppointmentGetAdviceAdminApiResponse =
  /** status 200 Success */ GetAdviceAdminResponse;
export type AppointmentGetAdviceAdminApiArg = {
  appointmentId: number;
};
export type AppointmentGetApiResponse =
  /** status 200 OK */ GetAppointmentResponse;
export type AppointmentGetApiArg = {
  id: number;
};
export type AppointmentSetApiResponse =
  /** status 200 OK */ SetAppointmentResponse;
export type AppointmentSetApiArg = {
  body: {
    id?: number;
  };
};
export type AppointmentGetUsersApiResponse =
  /** status 200 Success */ GetUsersResponse;
export type AppointmentGetUsersApiArg = {
  appointmentId: number;
};
export type AppointmentDeleteDocumentsAdminApiResponse =
  /** status 200 Success */ DeleteDocumentsAdminResponse;
export type AppointmentDeleteDocumentsAdminApiArg = {
  body: {
    id?: string;
    documents?: string[];
  };
};
export type AppointmentSetStatusApiResponse =
  /** status 200 OK */ SetStatusResponse;
export type AppointmentSetStatusApiArg = {
  body: {
    id?: number;
    status?: number;
    participant?: string;
    message?: string;
  };
};
export type AppointmentGetAllAdminApiResponse =
  /** status 200 Success */ GetAllAdminResponse;
export type AppointmentGetAllAdminApiArg = {
  /** Offset for the query */
  offset?: number;
  /** Limit for the query */
  limit?: number;
  /** Guild ID for the query */
  guildId?: number;
  /** Company Type ID for the query */
  companyTypeId?: number;
  /** Search query for the query */
  search?: string;
};
export type AppointmentDeleteAdminApiResponse =
  /** status 200 Success */ DeleteAdminResponse;
export type AppointmentDeleteAdminApiArg = {
  body: {
    appointmentID?: number;
    appointmentIDs?: number[];
  };
};
export type AppointmentDeleteAdviceAdminApiResponse =
  /** status 200 Success */ DeleteAdviceAdminResponse;
export type AppointmentDeleteAdviceAdminApiArg = {
  body: {
    appointmentID?: string;
    allData?: string;
  };
};
export type AppointmentShowIcsFrameApiResponse =
  /** status 200 Success */ ShowIcsFrameResponse;
export type AppointmentShowIcsFrameApiArg = {
  /** Appointment ID */
  id: number;
};
export type AppointmentGetUserAllAdminApiResponse =
  /** status 200 Success */ GetUserAllAdminResponse;
export type AppointmentGetUserAllAdminApiArg = {
  /** The ID of the appointment */
  appointmentId: number;
  /** The ID of the user device type */
  userDeviceTypeId?: number;
};
export type MediaGetAllApiResponse = /** status 200 Success */ GetAllResponse;
export type MediaGetAllApiArg = {
  /** Offset for the query */
  offset?: number;
  /** Limit for the query */
  limit?: number;
};
export type MediaGetApiResponse = /** status 200 Success */ GetMediaResponse;
export type MediaGetApiArg = {
  id: number;
};
export type MediaDeleteAdminApiResponse =
  /** status 200 Success */ DeleteAdminResponse;
export type MediaDeleteAdminApiArg = {
  body: {
    mediaID?: number;
    mediaIDs?: number[];
  };
};
export type MediaGetUserAllAdminApiResponse =
  /** status 200 Success */ GetUserAllAdminResponse;
export type MediaGetUserAllAdminApiArg = {
  /** Media ID */
  mediaId: number;
  /** User device type ID */
  userDeviceTypeId?: number;
};
export type MediaSetAdminApiResponse = /** status 200 OK */ SetAdminResponse;
export type MediaSetAdminApiArg = {
  body: {
    mediaID?: string;
    name?: string;
    description?: string;
    thumbnailFilename?: string;
    mediaFile?: string;
    shares?: string[][];
    shareApp?: number;
    shareGuilds?: string[];
  };
};
export type MediaGetAdminApiResponse =
  /** status 200 Success */ GetAdminResponse;
export type MediaGetAdminApiArg = {
  mediaId: number;
};
export type MediaSetViewCountApiResponse =
  /** status 200 Success */ SetViewCountResponse;
export type MediaSetViewCountApiArg = {
  body: {
    mediaID?: string;
  };
};
export type RssGetAllAdminApiResponse =
  /** status 200 OK */ GetAllAdminResponse;
export type RssGetAllAdminApiArg = void;
export type RssDeleteAdminApiResponse =
  /** status 200 Success */ DeleteAdminResponse;
export type RssDeleteAdminApiArg = {
  body: {
    id?: number;
  };
};
export type RssGetAdminApiResponse = /** status 200 Success */ GetAdminResponse;
export type RssGetAdminApiArg = {
  id: number;
};
export type RssSetAdminApiResponse = /** status 200 OK */ SetAdminResponse;
export type RssSetAdminApiArg = {
  body: {
    id?: number;
    name?: string;
    url?: string;
    data?: string;
    xml?: string;
  };
};
export type RssFetchChannelsApiResponse =
  /** status 200 Success */ FetchChannelsResponse;
export type RssFetchChannelsApiArg = {
  url: string;
  id: string;
};
export type FileUploadApiResponse = /** status 200 Success */ UploadResponse;
export type FileUploadApiArg = {
  body: {
    file?: string;
    ext?: string;
    thumbnail?: boolean;
    froala?: string;
  };
};
export type FileGeneratePdfApiResponse =
  /** status 200 Success */ FileGeneratePdfResponse;
export type FileGeneratePdfApiArg = {
  body: {
    header?: string;
    footer?: string;
    associationID?: string;
  };
};
export type ImportGetFieldsApiResponse = /** status 200 OK */ GetFieldsResponse;
export type ImportGetFieldsApiArg = void;
export type ImportGetPresetsApiResponse =
  /** status 200 OK */ GetPresetsResponse;
export type ImportGetPresetsApiArg = {
  associationId: number;
};
export type ImportGetPresetApiResponse =
  /** status 200 Success */ GetPresetResponse;
export type ImportGetPresetApiArg = {
  fileName: string;
  associationId: string;
};
export type ImportAddApiResponse = /** status 200 Success */ AddImportResponse;
export type ImportAddApiArg = {
  body: {
    fileName?: string;
  };
};
export type ImportAddExcelApiResponse = /** status 200 OK */ AddExcelResponse;
export type ImportAddExcelApiArg = {
  body: {
    allocations?: {
      deinverband?: string;
      format?: string;
      sheet?: string;
      file?: string[];
      backgroundcolor?: string;
      textcolor?: string;
    }[];
    fileName?: string;
    fileExtension?: string;
    importID?: number;
    associationID?: number;
  };
};
export type ImportSetProcessActiveApiResponse =
  /** status 200 Success */ SetProcessActiveResponse;
export type ImportSetProcessActiveApiArg = {
  body: {
    id?: number;
  };
};
export type ImportSetProcessInActiveApiResponse =
  /** status 200 Success */ SetProcessInActiveResponse;
export type ImportSetProcessInActiveApiArg = {
  body: {
    id?: number;
  };
};
export type DocumentGetGroupAllApiResponse =
  /** status 200 Success */ GetGroupAllResponse;
export type DocumentGetGroupAllApiArg = {
  /** Search string */
  search?: any;
  /** Document group ID */
  documentGroupId?: any;
};
export type DocumentGetGroupAllAdminApiResponse =
  /** status 200 Success */ GetGroupAllAdminResponse;
export type DocumentGetGroupAllAdminApiArg = void;
export type DocumentSetGroupAdminApiResponse =
  /** status 200 Success */ SetGroupAdminResponse;
export type DocumentSetGroupAdminApiArg = {
  body: {
    id?: string;
    name?: string;
    icon?: string;
    parentID?: string;
    sorting?: string;
  };
};
export type DocumentDeleteGroupAdminApiResponse =
  /** status 200 Success */ DeleteGroupAdminResponse;
export type DocumentDeleteGroupAdminApiArg = {
  body: {
    id?: number;
  };
};
export type DocumentGetGroupApiResponse =
  /** status 200 Success */ GetGroupResponse;
export type DocumentGetGroupApiArg = {
  id: number;
};
export type DocumentGetAllApiResponse =
  /** status 200 Success */ GetAllResponse;
export type DocumentGetAllApiArg = {
  id: number;
  offset?: number;
  limit?: number;
  search?: string;
};
export type DocumentGetApiResponse = /** status 200 Success */ GetResponse;
export type DocumentGetApiArg = {
  id: number;
};
export type DocumentGetByAffiliationIdApiResponse =
  /** status 200 Success */ GetByAffiliationIdResponse;
export type DocumentGetByAffiliationIdApiArg = {
  id: number;
};
export type DocumentSetAdminApiResponse = /** status 200 OK */ SetAdminResponse;
export type DocumentSetAdminApiArg = {
  body: {
    documents?: {
      documentID?: number;
      documentAffiliationID?: number;
      documentGroupID?: number;
      documentName?: string;
      documentFile?: string;
      documentExpireDate?: string;
    }[];
    shares?: number[][];
    shareApp?: number;
    shareMail?: number;
    shareEpost?: number;
    mailAccount?: string;
    mailSignature?: string;
    letterTemplate?: string;
    suggestion?: number;
    shareGuilds?: number[];
  };
};
export type DocumentSetImportAdminApiResponse =
  /** status 200 OK */ SetImportAdminResponse;
export type DocumentSetImportAdminApiArg = {
  body: {
    documents?: {
      documentCustomerID?: string;
      documentName?: string;
      documentGroupID?: string;
      documentUserID?: string;
      documentCompanyID?: string;
      documentFileExtension?: string;
    }[];
  };
};
export type DocumentGetAllAdminApiResponse =
  /** status 200 OK */ GetAllAdminResponse;
export type DocumentGetAllAdminApiArg = {
  /** Search query */
  search?: string;
  /** Offset */
  offset?: number;
  /** Limit */
  limit?: number;
  /** Guild ID */
  guildId?: number;
  /** Company Type ID */
  companyTypeId?: number;
};
export type DocumentGetAllByDateAdminApiResponse =
  /** status 200 Success */ GetAllByDateAdminResponse;
export type DocumentGetAllByDateAdminApiArg = {
  /** Offset for the query */
  offset?: number;
  /** Limit for the query */
  limit?: number;
};
export type DocumentGetAdviceAllAdminApiResponse =
  /** status 200 Success */ GetAdviceAllAdminResponse;
export type DocumentGetAdviceAllAdminApiArg = {
  /** Search string */
  search?: string;
  /** Offset */
  offset?: number;
  /** Limit */
  limit?: number;
  /** Guild ID */
  guildId?: number;
};
export type DocumentDeleteAdminApiResponse =
  /** status 200 Success */ DeleteAdminResponse;
export type DocumentDeleteAdminApiArg = {
  body: {
    documentID?: number;
    documentIDs?: number[];
  };
};
export type DocumentDeleteMultipleAdminApiResponse =
  /** status 200 Success */ DeleteMultipleAdminResponse;
export type DocumentDeleteMultipleAdminApiArg = {
  body: {
    documents?: number[];
  };
};
export type DocumentDeleteAdviceAdminApiResponse =
  /** status 200 Success */ DeleteAdviceAdminResponse;
export type DocumentDeleteAdviceAdminApiArg = {
  body: {
    documents?: string[];
    allData?: object;
  };
};
export type DocumentGetAdminApiResponse =
  /** status 200 Success */ GetAdminResponse;
export type DocumentGetAdminApiArg = {
  documentId: number;
};
export type DocumentGetAffiliationAdminApiResponse =
  /** status 200 Success */ GetAffiliationAdminResponse;
export type DocumentGetAffiliationAdminApiArg = {
  documentAffiliationId: number;
};
export type DocumentGetAdviceAdminApiResponse =
  /** status 200 Success */ GetAdviceAdminResponse;
export type DocumentGetAdviceAdminApiArg = {
  documentId: number;
};
export type DocumentGetUserAllAdminApiResponse =
  /** status 200 Success */ GetUserAllAdminResponse;
export type DocumentGetUserAllAdminApiArg = {
  documentId: number;
  userDeviceTypeId?: string;
};
export type CompanyGetGuildAllApiResponse =
  /** status 200 Success */ GetGuildAllResponse;
export type CompanyGetGuildAllApiArg = void;
export type CompanyGetUserAllAdminApiResponse =
  /** status 200 Success */ GetUserAllAdminResponse;
export type CompanyGetUserAllAdminApiArg = {
  id: number;
};
export type CompanyGetAllApiResponse =
  /** status 200 Success */ CompanyGetAllResponse;
export type CompanyGetAllApiArg = {
  /** Offset of the result set */
  offset?: number;
  /** Limit of the result set */
  limit?: number;
  /** Search string */
  search?: string;
};
export type CompanyGetUserAllApiResponse =
  /** status 200 Success */ GetUserAllResponse;
export type CompanyGetUserAllApiArg = {
  /** Offset of the result set */
  offset?: number;
  /** Limit of the result set */
  limit?: number;
  /** Search string */
  search?: string;
};
export type CompanyDeleteNavigationAdminApiResponse =
  /** status 200 Success */ DeleteNavigationAdminResponse;
export type CompanyDeleteNavigationAdminApiArg = {
  body: {
    id?: number;
  };
};
export type CompanyGetNavigationAdminApiResponse =
  /** status 200 OK */ GetNavigationAdminResponse;
export type CompanyGetNavigationAdminApiArg = {
  id: number;
};
export type CompanyGetNavigationAllAdminApiResponse =
  /** status 200 Success */ GetNavigationAllAdminResponse;
export type CompanyGetNavigationAllAdminApiArg = void;
export type CompanySetNavigationAdminApiResponse =
  /** status 200 Success */ SetNavigationAdminResponse;
export type CompanySetNavigationAdminApiArg = {
  body: {
    id?: number;
    name?: string;
    module?: string;
    text?: string;
    icon?: string;
  };
};
export type CompanySetNavigationOrderAdminApiResponse =
  /** status 200 OK */ SetNavigationOrderAdminResponse;
export type CompanySetNavigationOrderAdminApiArg = {
  body: {
    data?: string[];
  };
};
export type CompanyDeleteTypeAdminApiResponse =
  /** status 200 Success */ DeleteTypeAdminResponse;
export type CompanyDeleteTypeAdminApiArg = {
  body: {
    id?: number;
  };
};
export type CompanyGetTypeAllApiResponse =
  /** status 200 Success */ GetTypeAllResponse;
export type CompanyGetTypeAllApiArg = void;
export type CompanySetTypeAdminApiResponse =
  /** status 200 Success */ SetTypeAdminResponse;
export type CompanySetTypeAdminApiArg = {
  body: {
    id?: string;
    name?: string;
  };
};
export type CompanySetTypeOrderAdminApiResponse =
  /** status 200 Success */ SetTypeOrderAdminResponse;
export type CompanySetTypeOrderAdminApiArg = {
  body: {
    data?: string[];
  };
};
export type CompanySetGuildApiResponse =
  /** status 200 Success */ SetGuildResponse;
export type CompanySetGuildApiArg = {
  body: {};
};
export type CompanyGetAllAdminApiResponse =
  /** status 200 Success */ GetAllAdminResponse;
export type CompanyGetAllAdminApiArg = {
  /** Search string */
  search?: string;
  /** Offset */
  offset?: number;
  /** Limit */
  limit?: number;
};
export type CompanyDeleteAdminApiResponse =
  /** status 200 Success */ DeleteAdminResponse;
export type CompanyDeleteAdminApiArg = {
  body: {
    id?: number;
  };
};
export type CompanyDeleteChangesAdminApiResponse =
  /** status 200 Success */ DeleteChangesAdminResponse;
export type CompanyDeleteChangesAdminApiArg = {
  body: {
    id?: number;
  };
};
export type CompanyGetTypeAllAdminApiResponse =
  /** status 200 Success */ GetTypeAllAdminResponse;
export type CompanyGetTypeAllAdminApiArg = void;
export type CompanyGetTypeAdminApiResponse =
  /** status 200 Success */ GetTypeAdminResponse;
export type CompanyGetTypeAdminApiArg = {
  id: number;
};
export type CompanyGetAdminApiResponse =
  /** status 200 Success */ GetAdminResponse;
export type CompanyGetAdminApiArg = {
  id: number;
};
export type CompanySetAdminApiResponse =
  /** status 200 Success */ SetAdminResponse;
export type CompanySetAdminApiArg = {
  body: {
    companyID?: number;
    companyName1?: string;
    companyName2?: string;
    companyName3?: string;
    companyStreet?: string;
    companyZip?: string;
    companyLocation?: string;
    companyTelephone1?: string;
    companyTelephone2?: string;
    companyMobile?: string;
    companyMail?: string;
    companyFax?: string;
    companyWeb?: string;
    companyCustomer?: number;
  };
};
export type CompanyGetUsersApiResponse =
  /** status 200 Success */ GetUsersResponse;
export type CompanyGetUsersApiArg = {
  /** Offset of the result set */
  offset?: number;
  /** Limit of the result set */
  limit?: number;
  /** Search string */
  search?: string;
};
export type DiscussionGetAllApiResponse = /** status 200 OK */ GetAllResponse;
export type DiscussionGetAllApiArg = void;
export type DiscussionGetApiResponse = /** status 200 OK */ GetResponse;
export type DiscussionGetApiArg = {
  id: number;
};
export type DiscussionGetEditApiResponse = /** status 200 OK */ GetEditResponse;
export type DiscussionGetEditApiArg = {
  id: number;
};
export type DiscussionAddApiResponse =
  /** status 200 OK */ DiscussionAddResponse;
export type DiscussionAddApiArg = {
  body: {
    name?: string;
    private?: number;
    companies?: string;
    companyTypes?: string;
    users?: string;
  };
};
export type DiscussionEditApiResponse =
  /** status 200 OK */ EditDiscussionResponse;
export type DiscussionEditApiArg = {
  body: {
    id?: number;
    name?: string;
    usersAdd?: string;
    usersRemove?: string;
  };
};
export type DiscussionAddMessageApiResponse =
  /** status 200 OK */ AddMessageResponse;
export type DiscussionAddMessageApiArg = {
  body: {
    id?: number;
    message?: string;
    images?: string;
    isJson?: number;
  };
};
export type DiscussionDeleteApiResponse =
  /** status 200 Success */ DeleteResponse;
export type DiscussionDeleteApiArg = {
  body: {
    id?: number;
  };
};
export type DiscussionLeaveApiResponse = /** status 200 OK */ LeaveResponse;
export type DiscussionLeaveApiArg = {
  body: {
    id?: number;
  };
};
export type DiscussionSetMessagesReadApiResponse =
  /** status 200 Success */ SetMessagesReadResponse;
export type DiscussionSetMessagesReadApiArg = {
  body: {
    id?: string;
  };
};
export type NewsGetAllApiResponse =
  /** status 200 Success */ NewsGetAllResponse;
export type NewsGetAllApiArg = {
  /** Offset for the news */
  offset?: number;
  /** Limit for the news */
  limit?: number;
  /** Search query for the news */
  search?: string;
};
export type NewsGetApiResponse = /** status 200 OK */ GetNewsResponse;
export type NewsGetApiArg = {
  id: number;
};
export type NewsGetCommentAllAdminApiResponse =
  /** status 200 Success */ GetCommentAllAdminResponse;
export type NewsGetCommentAllAdminApiArg = {
  newsId: number;
};
export type NewsAddCommentApiResponse = /** status 200 OK */ AddCommentResponse;
export type NewsAddCommentApiArg = {
  body: {
    id?: number;
    comment?: string;
  };
};
export type NewsDeleteCommentAdminApiResponse =
  /** status 200 Success */ DeleteCommentAdminResponse;
export type NewsDeleteCommentAdminApiArg = {
  body: {
    id?: number;
  };
};
export type NewsDeleteDocumentsAdminApiResponse =
  /** status 200 Success */ DeleteDocumentsAdminResponse;
export type NewsDeleteDocumentsAdminApiArg = {
  body: {
    id?: string;
    documents?: string[];
  };
};
export type NewsDeleteAdminApiResponse =
  /** status 200 Success */ DeleteAdminResponse;
export type NewsDeleteAdminApiArg = {
  body: {
    newsID?: number;
    newsIDs?: number[];
  };
};
export type NewsDeleteAdviceAdminApiResponse =
  /** status 200 Success */ DeleteAdviceAdminResponse;
export type NewsDeleteAdviceAdminApiArg = {
  body: {
    newsID?: string;
    allData?: string;
  };
};
export type NewsGetUserAllAdminApiResponse =
  /** status 200 Success */ GetUserAllAdminResponse;
export type NewsGetUserAllAdminApiArg = {
  newsId: number;
  userDeviceTypeId?: string;
};
export type NewsSetAdminApiResponse = /** status 200 OK */ SetAdminResponse;
export type NewsSetAdminApiArg = {
  body: {
    newsID?: number;
    appointmentID?: number;
    documents?: number[];
    name?: string;
    dateExpireNews?: string;
    description?: string;
    image?: number;
    comments?: number;
    shares?: number[][];
    shareApp?: number;
    shareMail?: number;
    shareEpost?: number;
    mailAccount?: string;
    mailSignature?: string;
    letterTemplate?: string;
    shareGuilds?: number[];
  };
};
export type NewsGetDocumentsApiResponse =
  /** status 200 Success */ GetDocumentsResponse;
export type NewsGetDocumentsApiArg = {
  newsId: number;
};
export type NewsGetAdviceAdminApiResponse =
  /** status 200 Success */ GetAdviceAdminResponse;
export type NewsGetAdviceAdminApiArg = {
  newsId: number;
};
export type NewsAutodeleteNewsApiResponse =
  /** status 200 Success */ AutodeleteNewsResponse;
export type NewsAutodeleteNewsApiArg = {
  body: {
    nid?: number;
    ndoid?: number;
    naid?: number;
    nexpiredate?: string;
  };
};
export type PropertyXxApiResponse = /** status 200 Success */ XxResponse;
export type PropertyXxApiArg = void;
export type MailSetTemplateAdminApiResponse =
  /** status 200 OK */ SetTemplateAdminResponse;
export type MailSetTemplateAdminApiArg = {
  body: {
    id?: number;
    type?: number;
    template?: string;
  };
};
export type GetProductivityResponse = {
  result?: number;
  data?: {
    yearMonth?: string;
    summary?: {
      productivityID?: number;
      productivityName?: string;
      productivityCount?: number;
    }[];
  }[];
};
export type GetCoreResponse = {
  result?: number;
  data?: {
    companyCount?: number;
    userCount?: number;
    userApp?: number;
    userLogins?: number;
  };
};
export type GetStyleResponse = {
  result?: number;
  data?: {
    associationStylePrimaryColor?: string;
    associationStyleSecondaryColor?: string;
    associationStyleButtonColor?: string;
    associationStyleLogo?: string;
    associationName?: string;
    associationShareMode?: number;
    associationSettingsMode?: number;
    associationAppVersionMin?: string;
  };
};
export type GetStyleByDomainResponse = {
  result?: number;
  data?: {
    associationDomainID?: number;
    associationDomain?: string;
    associationStylePrimaryColor?: string;
    associationStyleSecondaryColor?: string;
    associationStyleButtonColor?: string;
    associationStyleLogo?: string;
    associationID?: number;
    associationName?: string;
    associationAddress?: string;
    associationZip?: string;
    associationCity?: string;
    associationShareMode?: number;
    associationSettingsMode?: number;
  };
};
export type GetAllResponse = {
  result?: number;
  data?: {
    discussionID?: number;
    discussionName?: string;
    discussionDescription?: string;
    discussionCreationdate?: string;
    discussionMessageUserFirstname?: string;
    discussionMessageUserLastname?: string;
    discussionMessage?: string;
    discussionMessageCreationdate?: string;
    discussionMessageFileCount?: number;
    users?: string[];
    userCount?: number;
    messageUnreadCount?: number;
    messageReadCount?: number;
    messageCount?: number;
    lastMessage?: string;
  }[];
};
export type GetAssociationResponse = {
  result?: number;
  data?: {
    associationID?: number;
    associationName?: string;
    associationCreationdate?: string;
    associationAddress?: string;
    associationZip?: string;
    associationCity?: string;
    notificationAndroidKey?: string;
    notificationIosFile?: string;
    notificationIosAppID?: string;
    notificationIosDevelopmentTeam?: string;
    notificationIosKeyID?: string;
    associationStylePrimaryColor?: string;
    associationStyleSecondaryColor?: string;
    associationStyleButtonColor?: string;
    associationTemplateName?: string;
    associationHeader?: string;
    associationFooter?: string;
    associationTemplateFile?: string;
    associationUseTemplate?: number;
    associationStyleLogo?: string;
    associationDomains?: {
      domainID?: number;
      domainName?: string;
      domainHttps?: string;
    }[];
  };
};
export type SetAssociationResponse = {
  result?: string;
};
export type DeleteResponse = {
  result?: boolean;
};
export type SetDomainAdminResponse = {
  result?: boolean;
};
export type SetUseTemplateAdminResponse = {
  result?: boolean;
};
export type SetTemplateFileAdminResponse = {
  result?: boolean;
};
export type DeleteImageAdminResponse = {
  result?: boolean;
};
export type GetAdminUserAllResponse = {
  result?: number;
  data?: {
    userID?: number;
    userFirstname?: string;
    userLastname?: string;
    userMail?: string;
    companyID?: number;
    companyName1?: string;
    companyName2?: string;
    companyName3?: string;
    userApiKey?: string;
    associationDomain?: string;
    companyDemo?: boolean;
    userPassword?: string;
  }[];
};
export type GetImagesAllAdminResponse = {
  result?: number;
  data?: {
    documentID?: number;
    documentName?: string;
    documentFile?: string;
    documentCreationdate?: string;
  }[];
};
export type AddImageAdminResponse = {
  result?: boolean;
};
export type SetLetterAdminResponse = {
  result?: boolean;
};
export type GetLetterAllAdminResponse = {
  result?: number;
  data?: {
    letterTemplateID?: number;
    letterTemplateName?: string;
    letterTemplateHeader?: string;
    letterTemplateFooter?: string;
    letterUseTemplate?: boolean;
  }[];
};
export type GetLetterAdminResponse = {
  result?: number;
  data?: {
    letterTemplateID?: number;
    letterTemplateName?: string;
    letterTemplateHeader?: string;
    letterTemplateFooter?: string;
    letterTemplateFile?: string;
    letterUseTemplate?: boolean;
    letterAssociationID?: number;
  };
};
export type DeleteAssociationLetterAdminResponse = {
  result?: number;
};
export type SetGeneralResponse = {
  result?: boolean;
};
export type SetSettingsModeResponse = {
  result?: boolean;
};
export type SetGuildAdminResponse = {
  result?: number;
};
export type DeleteGuildAdminResponse = {
  result?: number;
};
export type GetGuildAdminResponse = {
  result?: number;
  data?: {
    associationID?: number;
    associationName?: string;
    associationCreationdate?: string;
    associationAddress?: string;
    associationZip?: string;
    associationCity?: string;
    guildID?: number;
    accepted?: number;
  }[];
};
export type GetGuildReceivedAllAdminResponse = {
  result?: number;
  data?: {
    associationID?: number;
    associationName?: string;
    associationCreationdate?: string;
    associationAddress?: string;
    associationZip?: string;
    associationCity?: string;
    guildID?: number;
    accepted?: number;
    guildLogo?: string;
    guildName?: string;
    guildCreationdate?: string;
  }[];
  acceptedCount?: number;
  notAcceptedCount?: number;
};
export type GetGuildSharedAdminAllResponse = {
  result?: number;
  data?: {
    associationID?: number;
    associationName?: string;
    associationCreationdate?: string;
    associationAddress?: string;
    associationZip?: string;
    associationCity?: string;
    guildID?: number;
    guildName?: string;
    accepted?: number;
    sharedGuilds?: string[];
  }[];
};
export type GetErrorsCountResponse = {
  result?: number;
  mailErrorCount?: number;
};
export type GetErrorsResponse = {
  result?: number;
  data?: {
    notificationID?: number;
    associationEmailServer?: string;
    associationEmailMail?: string;
    associationEmailUser?: string;
    notificationResponse?: object;
    notificationResponseHuman?: string;
    notificationSubject?: string;
    notificationReserveDate?: string;
    notificationCreationDate?: string;
  }[];
};
export type SetErrorResendResponse = {
  result?: boolean;
};
export type SetErrorDeleteResponse = {
  result?: boolean;
};
export type GetAllAdminResponse = {
  result?: number;
  data?: {
    companyID?: number;
    companyName?: string;
    companyName1?: string;
    companyName2?: string;
    companyName3?: string;
    userAdminCount?: number;
    userCount?: number;
    userLoginsCount?: number;
    hasEpost?: number;
    hasMail?: number;
    hasApp?: number;
  }[];
};
export type GetListAdminResponse = {
  result?: number;
  data?: {
    text?: string;
    href?: string;
    target?: string;
  }[];
};
export type GetAdviceAllAdminResponse = {
  result?: number;
  data?: {
    adviceDocumentID?: number;
    documentID?: number;
    documentName?: string;
    documentFile?: string;
    documentCreationdate?: string;
    documentGuildCount?: number;
    associationID?: number;
    associationName?: string;
    masterID?: number;
    masterName?: string;
  }[];
};
export type DeleteAdminResponse = {
  result?: number;
};
export type DeleteAdviceAdminResponse = {
  result?: number;
};
export type SetGroupAdminResponse = {
  result?: number;
};
export type DeleteGroupAdminResponse = {
  result?: number;
};
export type GetGroupAllAdminResponse = {
  result?: number;
  data?: {
    documentGroupID?: number;
    documentGroupName?: string;
    documentGroupIcon?: string;
    documentGroupSorting?: string;
    documentGroupParentID?: number;
    documentGroupParentName?: string;
    documentGroupParentIcon?: string;
    documentGroupCreationdate?: string;
    documentGroupLevel?: string;
  }[];
};
export type SetAdminResponse = {
  result?: number;
};
export type GetAdminResponse = {
  result?: number;
  data?: {
    companyID?: number;
    companyName1?: string;
    companyName2?: string;
    companyName3?: string;
    companyStreet?: string;
    companyZip?: string;
    companyLocation?: string;
    companyTelephone1?: string;
    companyTelephone2?: string;
    companyMobile?: string;
    companyMail?: string;
    companyFax?: string;
    companyWeb?: string;
    companyCreationdate?: string;
    companyCustomer?: number;
  };
};
export type GetAdviceAdminResponse = {
  result?: number;
  data?: {
    adviceNewsID?: number;
    newsID?: number;
    newsName?: string;
    newsDescription?: string;
    newsImage?: string;
    newsCreationdate?: string;
    appointmentID?: number;
    adviceAppointmentID?: number;
    appointmentName?: string;
    appointmentDescription?: string;
    appointmentDate?: string;
    appointmentEndDate?: string;
    appointmentCreationdate?: string;
    appointmentAddress?: string;
    appointmentLocation?: string;
    appointmentZip?: string;
    appointmentLat?: string;
    appointmentLng?: string;
    newsDocuments?: {
      adviceDocumentID?: number;
      documentID?: number;
      documentName?: string;
      documentFile?: string;
      documentCreationdate?: string;
    }[];
    adviceDocumentIDs?: number[];
    newsGuildCount?: number;
    masterID?: number;
    masterName?: string;
    associationID?: number;
    associationName?: string;
  };
};
export type GetGroupAdminResponse = {
  result?: number;
  data?: {
    linkGroupID?: number;
    linkGroupName?: string;
    linkGroupCreationdate?: string;
  };
};
export type GetDataAllAdminResponse = {
  result?: number;
  data?: {
    propertyTabID?: number;
    propertyTabName?: string;
    propertyTabTypeID?: number;
    propertyTabTypeName?: string;
    propertyTabBlocks?: {
      propertyTabID?: number;
      propertyTabBlockID?: number;
      propertyTabBlockName?: string;
      propertyBlockFields?: {
        blockID?: number;
        propertyFieldID?: number;
        propertyFieldName?: string;
        propertyFieldTypeID?: number;
        propertyValue?: string;
        propertyFieldTypeName?: string;
        options?: {
          optionID?: number;
          optionName?: string;
          propertyValueID?: number;
          propertyValue?: string;
          blockID?: number;
          selected?: number;
        }[];
      }[];
    }[];
  };
};
export type GetDataAllResponse = {
  result?: number;
  data?: {
    available?: {
      blockID?: number;
      blockName?: string;
      blockFields?: {
        fieldID?: number;
        fieldName?: string;
        fieldTypeID?: number;
        fieldTypeName?: string;
      }[];
    }[];
    configured?: object[];
  };
};
export type SetDataAllResponse = {
  result?: number;
};
export type GetTabDataAdminResponse = {
  result?: number;
  data?: {
    propertyTabID?: number;
    propertyTabName?: string;
    propertyTabBlocks?: {
      propertyTabBlockID?: number;
      propertyTabBlockName?: string;
      propertyBlockFields?: {
        propertyFieldID?: number;
        propertyFieldName?: string;
        propertyFieldRequestable?: boolean;
        propertyFieldTypeID?: number;
        propertyFieldTypeName?: string;
        selectedTextFieldID?: number;
        selectedTextField?: string;
        selectedOptionFieldID?: number;
        selectedOptionField?: string;
      }[];
    }[];
  };
};
export type SetFieldOptionAdminResponse = {
  result?: number;
};
export type SetFieldOptionPropertyAdminResponse = {
  result?: number;
};
export type SetFieldAdminResponse = {
  result?: number;
};
export type SetValueAdminResponse = {
  result?: number;
};
export type GetFieldAdminResponse = {
  result?: number;
  data?: {
    typeID?: number;
    typeName?: string;
    fieldName?: string;
    optionID?: number;
    optionName?: string;
    propertyID?: number;
    propertyValue?: string;
    selected?: boolean;
  }[];
};
export type GetFieldTypeAdminAllResponse = {
  result?: number;
  data?: {
    typeID?: number;
    typeName?: string;
    typeCreationdate?: string;
  }[];
};
export type GetTabAdminAllResponse = {
  result?: number;
  data?: {
    propertyTabID?: number;
    propertyTabAssociationID?: string;
    propertyTabName?: string;
    propertyTabTypeID?: string;
    propertyTabTypeName?: string;
    propertyTabOrder?: string;
    propertyTabCreationdate?: string;
  }[];
};
export type GetTabTypeAllAdminResponse = {
  result?: number;
  data?: {
    typeID?: number;
    typeName?: string;
  }[];
};
export type GetTabAdminResponse = {
  result?: number;
  data?: {
    propertyTabID?: number;
    propertyTabTypeID?: number;
    propertyTabTypeName?: string;
    propertyTabAssociationID?: number;
    propertyTabName?: string;
    propertyTabOrder?: number;
    propertyTabCreationdate?: string;
  };
};
export type SetTabAdminResponse = {
  result?: number;
};
export type DeleteTabAdminResponse = {
  result?: number;
};
export type DeleteFieldAdminResponse = {
  result?: number;
};
export type DeleteOptionAdminResponse = {
  result?: number;
};
export type SetTabOrderAdminResponse = {
  result?: number;
};
export type SetFieldOrderAdminResponse = {
  result?: number;
};
export type GetTabBlockAdminAllResponse = {
  result?: number;
  data?: {
    propertyTabBlockID?: number;
    propertyTabID?: number;
    propertyTabBlockName?: string;
    propertyTabBlockOrder?: string;
    propertyTabBlockCreationdate?: string;
  }[];
};
export type GetTabBlockAdminResponse = {
  result?: number;
  data?: {
    propertyTabBlockID?: number;
    propertyTabID?: number;
    propertyTabBlockName?: string;
    propertyTabBlockOrder?: string;
    propertyTabBlockCreationdate?: string;
  };
};
export type SetTabBlockAdminResponse = {
  result?: number;
};
export type AddTabDocumentGroupAdminResponse = {
  result?: number;
};
export type GetTabDocumentGroupsAdminResponse = {
  result?: number;
  data?: {
    propertyTabDocumentGroupID?: number;
    propertyTabDocumentGroupName?: string;
  }[];
};
export type DeleteTabBlockAdminResponse = {
  result?: number;
};
export type SetTabBlockOrderAdminResponse = {
  result?: number;
};
export type GetTabDocumentAllAdminResponse = {
  result?: number;
  data?: {
    propertyTabDocumentGroupID?: number;
    propertyTabDocumentGroupName?: string;
    propertyTabDocumentID?: number;
    propertyTabDocumentName?: string;
    propertyTabDocumentFile?: string;
    propertyTabDocumentExt?: string;
    companyID?: number;
    isImage?: boolean;
  }[];
};
export type GetTabTimelineAllAdminResponse = {
  result?: number;
  data?: {
    propertyTabTimelineID?: number;
    propertyTabTimelineText?: string;
    propertyTabTimelineDate?: string;
    companyID?: number;
    files?: {
      propertyTabTimelineFileID?: number;
      propertyTabTimelineFileName?: string;
      propertyTabTimelineFile?: string;
      propertyTabTimelineFileExt?: string;
      isImage?: boolean;
    }[];
  }[];
};
export type SetTabDocumentAdminResponse = {
  result?: number;
};
export type SetTabTimelineAdminResponse = {
  result?: number;
};
export type DeleteTabTimelineAdminResponse = {
  result?: number;
};
export type DeleteTabDocumentAdminResponse = {
  result?: number;
};
export type DeleteTabTimelineFileAdminResponse = {
  result?: number;
};
export type GetExportDataAdminResponse = {
  result?: number;
  data?: {
    propertyTabID?: number;
    propertyTabName?: string;
    propertyTabTypeID?: number;
    propertyTabBlocks?: {
      propertyTabBlockID?: number;
      propertyTabBlockName?: string;
      propertyBlockFields?: {
        propertyFieldID?: number;
        propertyFieldName?: string;
        propertyFieldTypeID?: number;
        propertyFieldTypeName?: string;
      }[];
    }[];
  }[];
};
export type AddExportPresetItemAdminResponse = {
  result?: number;
};
export type AddExportPresetAdminResponse = {
  result?: number;
};
export type GetExportPresetAdminResponse = {
  result?: number;
  data?: {
    exportPresetID?: number;
    exportPresetName?: string;
    exportPresetCompanyID?: number;
    exportPresetItemName?: string;
    exportPresetItemID?: number;
    propertyFieldID?: number;
    propertyFieldName?: string;
  }[];
};
export type LoginResponse = {
  result?: number;
  apikey?: string;
};
export type IsValidApikeyResponse = {
  result?: boolean;
};
export type GetNavigationResponse = {
  result?: number;
  navigation?: {
    navigationID?: number;
    navigationName?: string;
    navigationText?: string;
    navigationIcon?: string;
    navigationModule?: string;
    navigationUnreadCount?: number;
  }[];
  info?: {
    guildID?: number;
    guildName?: string;
    guildLogo?: string;
    companyTypeID?: number;
    companyTypeName?: string;
    companyID?: number;
    companyName1?: string;
    companyName2?: string;
    userID?: number;
    userFirstname?: string;
    userLastname?: string;
  };
};
export type GetNavigationItemResponse = {
  result?: number;
  data?: {
    navigationID?: number;
    navigationName?: string;
    navigationText?: string;
    navigationModule?: string;
  };
};
export type SetNavigationCountEmptyResponse = {
  result?: boolean;
};
export type GetDetailsResponse = {
  result?: number;
  data?: {
    userID?: number;
    userGender?: number;
    userFirstname?: string;
    userLastname?: string;
    userMail?: string;
    companyID?: number;
    companyLogo?: string;
    companyName1?: string;
    companyName2?: string;
    companyName3?: string;
    companyStreet?: string;
    companyZip?: string;
    companyLocation?: string;
    companyMail?: string;
    companyTelephone1?: string;
    companyTelephone2?: string;
    companyMobile?: string;
    companyFax?: string;
    companyWeb?: string;
    userAdmin?: number;
    userAuthor?: number;
    userSuperAdmin?: number;
    associationID?: number;
    associationSettingsMode?: number;
  };
};
export type GetDeviceAdminResponse = {
  result?: number;
  data?: {
    userDeviceID?: number;
    osDevice?: string;
    deviceID?: string;
    deviceName?: string;
    deviceSystem?: string;
    deviceVersion?: string;
    deviceToken?: string;
  }[];
};
export type DeleteDeviceResponse = {
  result?: boolean;
};
export type AddDeviceResponse = {
  result?: boolean;
};
export type SendLoginAdminResponse = {
  result?: number;
};
export type GetAdminAllAdminResponse = {
  result?: number;
  data?: {
    guildID?: number;
    guildLogo?: string;
    guildName?: string;
    guildParentID?: number;
    guildParentName?: string;
    guildParentLogo?: string;
    guildCreationdate?: string;
    guildChildCount?: number;
  }[];
};
export type DeleteUserAdminResponse = {
  result?: boolean;
};
export type AddGuildAdminResponse = {
  result?: number;
};
export type SetPasswordRequestResponse = {
  result?: number;
};
export type ResetPasswordResponse = {
  result?: number;
};
export type SetChangesResponse = {
  result?: number;
  password?: number;
};
export type GetChangesResponse = {
  result?: number;
  data?: {
    changeID?: number;
    changeCreationdate?: string;
    userID?: number;
    userGender?: number;
    userFirstname?: string;
    userLastname?: string;
    userMail?: string;
    companyID?: number;
    companyLogo?: string;
    companyName1?: string;
    companyName2?: string;
    companyName3?: string;
    street?: string;
    zip?: string;
    location?: string;
    mail?: string;
    telephone1?: string;
    telephone2?: string;
    mobile?: string;
    fax?: string;
    web?: string;
    changes?: object;
  }[];
};
export type GetChangesAllAdminResponse = {
  result?: number;
  data?: {
    changes?: {
      changeID?: number;
      changeCreationdate?: string;
      userID?: number;
      userGender?: number;
      userFirstname?: string;
      userLastname?: string;
      userMail?: string;
      companyID?: number;
      companyLogo?: string;
      companyName1?: string;
      companyName2?: string;
      companyName3?: string;
      street?: string;
      zip?: string;
      location?: string;
      mail?: string;
      telephone1?: string;
      telephone2?: string;
      mobile?: string;
      fax?: string;
      web?: string;
      changes?: object;
    }[];
    properties?: {
      propertyID?: number;
      propertyCompanyID?: number;
      fieldID?: number;
      properyValue?: string;
    }[];
  };
};
export type SetChangeAdminResponse = {
  result?: number;
  data?: object[];
};
export type DeleteChangesAdminResponse = {
  result?: number;
};
export type GetByActivationKeyResponse = {
  result?: number;
  data?: {
    userApikey?: string;
    userID?: number;
    associationName?: string;
  };
};
export type GetAccountAllAdminResponse = {
  result?: number;
  data?: {
    mailAccountID?: number;
    mailAccountServer?: string;
    mailAccountTypeID?: number;
    mailAccountActive?: number;
    mailAccountTypeName?: string;
    mailAccountPort?: string;
    mailAccountUser?: string;
    mailAccountName?: string;
    mailAccountSSL?: string;
    mailAccountMail?: string;
    mailAccountPassword?: string;
    mailAccountCreationdate?: string;
  }[];
};
export type GetAccountAdminResponse = {
  result?: number;
  data?: {
    mailAccountID?: number;
    mailAccountTypeID?: number;
    mailAccountTypeName?: string;
    mailAccountActive?: number;
    mailAccountServer?: string;
    mailAccountPort?: string;
    mailAccountUser?: string;
    mailAccountName?: string;
    mailAccountSSL?: string;
    mailAccountMail?: string;
    mailAccountPassword?: string;
    mailAccountCreationdate?: string;
  };
};
export type GetAccountTypeAllAdminResponse = {
  result?: number;
  data?: {
    mailAccountTypeID?: number;
    mailAccountTypeValue?: string;
  }[];
};
export type DeleteAccountAdminResponse = {
  result?: number;
};
export type SetAccountActiveAdminResponse = {
  result?: number;
};
export type SetSignatureActiveAdminResponse = {
  result?: number;
};
export type AddSignatureAdminResponse = {
  result?: number;
};
export type GetSignatureAllAdminResponse = {
  result?: number;
  data?: {
    mailSignatureID?: number;
    mailSignatureName?: string;
    mailSignatureActive?: boolean;
    mailSignature?: string;
  }[];
};
export type GetSignatureAdminResponse = {
  result?: number;
  data?: {
    mailSignatureID?: number;
    mailSignatureName?: string;
    mailSignatureActive?: boolean;
    mailSignature?: string;
  };
};
export type EditSignatureAdminResponse = {
  result?: number;
};
export type DeleteSignatureAdminResponse = {
  result?: number;
};
export type GetMailContentAdminResponse = {
  result?: number;
  data?: {
    mailContentID?: number;
    mailContentBody?: string;
  };
};
export type SetMailAdminResponse = {
  result?: number;
};
export type SetAccountAdminResponse = {
  result?: number;
  checkResult?: boolean;
  checkError?: string;
};
export type SetAccountAdminOffice365Response = {
  result?: number;
};
export type GetTemplatesAllAdminResponse = {
  result?: number;
  data?: {
    templateID?: number;
    templateName?: string;
    templateDesc?: string;
    notificationTypeName?: string;
    notificationTypeID?: number;
  }[];
};
export type GetTemplatesAllResponse = {
  result?: number;
  data?: {
    id?: number;
    name?: string;
    content?: string;
  }[];
};
export type GetTemplateResponse = {
  result?: number;
  data?: {
    mailID?: number;
    mailName?: string;
    mailSender?: string;
    mailTemplate?: string;
    mailNlToBr?: boolean;
    mailStatus?: string;
    associationID?: number;
  };
};
export type DeleteTemplateResponse = {
  result?: boolean;
};
export type AddTemplateEmptyResponse = {
  result?: boolean;
};
export type SetTemplateResponse = {
  result?: number;
  data?: object;
};
export type AddTestResponse = {
  result?: number;
  data?: object;
};
export type AutoDeleteResponse = {
  result?: string;
};
export type GenerateEpostFilesResponse = {
  result?: {
    status?: string;
    message?: string;
    data?: object;
  };
};
export type ConvertPdfFileResponse = {
  status?: number;
  message?: string;
};
export type MergePdfFilesResponse = string;
export type ResendResponse = {
  success?: boolean;
  message?: string;
};
export type GetReceiverCountsResponse = {
  result?: number;
  data?: {
    addressCount?: number;
    mailCount?: number;
    appCount?: number;
  };
};
export type SetReceiverCountsResponse = {
  result?: number;
};
export type GetImageInfoResponse = {
  mime?: string;
  path?: string;
};
export type GetTypesResponse = {
  result?: number;
  data?: {
    notificationTypeID?: number;
    notificationTypeName?: string;
  }[];
};
export type SetTypesResponse = {
  result?: number;
};
export type GetUserAllAdminResponse = {
  result?: number;
  data?: {
    userID?: number;
    userFirstname?: string;
    userLastname?: string;
    userReceiverCount?: number;
  }[];
};
export type SetUserAllAdminResponse = {
  result?: number;
};
export type AddUserAdminResponse = {
  result?: boolean;
};
export type AddTestMailResponse = {
  result?: number;
};
export type RefreshAccessTokensResponse = {
  aemail?: string;
  aeid?: number;
  aerefreshtoken?: string;
  accessToken?: string;
};
export type ExceptionResponse = {
  result?: number;
  exception?: {
    code?: number;
    message?: string;
    file?: string;
    line?: number;
  };
};
export type SetNotifictionTemplateResponse = {
  result?: boolean;
};
export type GetNotificationTemplateResponse = {
  result?: object;
};
export type AddAdminAdminResponse = {
  result?: number;
};
export type DeleteAdminAdminResponse = {
  result?: number;
};
export type GetChangelogResponse = {
  result?: number;
  data?: {
    changelogDescription?: string;
    changelogLogdate?: string;
    changelogVersion?: string;
    changelogLogdateMonth?: string;
  }[];
};
export type SetAppointmentResponse = {
  result?: number;
  data?: {
    appointmentID?: number;
  };
};
export type GetAppointmentResponse = {
  result?: number;
  data?: {
    appointmentID?: number;
    appointmentName?: string;
    appointmentDescription?: string;
    appointmentDescriptionPlain?: string;
    appointmentDate?: string;
    appointmentDateEnd?: string;
    appointmentCreationdate?: string;
    appointmentStatus?: number;
    appointmentMessage?: string;
    appointmentParticipant?: string;
    appointmentAddress?: string;
    appointmentLocation?: string;
    appointmentZip?: string;
    appointmentLat?: string;
    appointmentLng?: string;
    appointmentUsers?: {
      userID?: number;
      userFirstname?: string;
      userLastname?: string;
      companyID?: number;
      companyName1?: string;
      companyName2?: string;
      companyName3?: string;
      appointmentStatus?: number;
      appointmentMessage?: string;
      appointmentParticipant?: string;
    }[];
    appointmentDateExpire?: string;
    appointmentResponseAllow?: number;
    appointmentResponseMemberNumberAllow?: number;
    appointmentResponseTextAllow?: number;
    appointmentResponsePlaceholder?: string;
    appointmentMemberView?: number;
    appointmentDocuments?: {
      documentID?: number;
      documentName?: string;
      documentFile?: string;
      documentExtension?: string;
    }[];
    documentID?: number;
    documentName?: string;
    documentFile?: string;
    documentExtension?: string;
    appointmentGuilds?: string[];
  };
};
export type GetUsersResponse = {
  result?: number;
  data?: {
    companyID?: number;
    companyName1?: string;
    companyName2?: string;
    companyName3?: string;
    companyStreet?: string;
    companyZip?: string;
    companyLocation?: string;
    userID?: number;
    userFirstname?: string;
    userLastname?: string;
    userGender?: number;
    userMail?: string;
  }[];
};
export type DeleteDocumentsAdminResponse = {
  result?: number;
};
export type SetStatusResponse = {
  result?: number;
};
export type ShowIcsFrameResponse = {
  result?: number;
  data?: string;
};
export type GetMediaResponse = {
  result?: number;
  data?: {
    mediaID?: number;
    mediaName?: string;
    mediaDescription?: string;
    mediaThumbnailFile?: string;
    mediaFile?: string;
    mediaCreationdate?: string;
  };
};
export type SetViewCountResponse = {
  result?: boolean;
};
export type FetchChannelsResponse = {
  result?: number;
  data?: object[];
};
export type UploadResponse = {
  success?: boolean;
  message?: string;
  data?: object;
};
export type FileGeneratePdfResponse = {
  result?: boolean;
  file?: string;
};
export type GetFieldsResponse = {
  result?: number;
  data?: {
    importFieldTypeID?: number;
    importFieldTypeName?: string;
    fields?: {
      importFieldID?: number;
      importFieldName?: string;
    }[];
  };
};
export type GetPresetsResponse = {
  result?: number;
  data?: {
    importPresetID?: number;
    importPresetFileName?: string;
    importPresetCreationDate?: string;
  }[];
};
export type GetPresetResponse = {
  result?: number;
  data?: {
    fieldID?: number;
    sheet?: string;
    format?: string;
    backgroundColor?: string;
    textColor?: string;
    fieldTypeID?: number;
    fieldTypeName?: string;
    fileFields?: string[];
  };
};
export type AddImportResponse = {
  result?: number;
  data?: {
    success?: boolean;
    file?: string;
    importID?: number;
    internFile?: string;
    data?: object[];
  };
};
export type AddExcelResponse = {
  result?: number;
  error?: number;
  data?: object[];
};
export type SetProcessActiveResponse = {
  result?: number;
};
export type SetProcessInActiveResponse = {
  result?: number;
};
export type GetGroupAllResponse = {
  result?: number;
  data?: {
    documentGroupID?: number;
    documentGroupName?: string;
    documentGroupIcon?: string;
    documentGroupParentID?: number;
    documentGroupParentName?: string;
    documentGroupParentIcon?: string;
    documentGroupCreationdate?: string;
    documentGroupUnreadCount?: number;
  }[];
};
export type GetGroupResponse = {
  result?: number;
  data?: {
    documentGroupID?: number;
    documentGroupName?: string;
    documentGroupIcon?: string;
    documentGroupSorting?: string;
    documentGroupParentID?: number;
    documentGroupParentName?: string;
    documentGroupParentIcon?: string;
    documentGroupChildCount?: number;
    documentGroupCreationdate?: string;
  };
};
export type GetResponse = {
  result?: number;
  data?: {
    discussionID?: number;
    discussionName?: string;
    discussionDescription?: string;
    discussionCreationdate?: string;
    companyID?: number;
    isOwner?: number;
    userCount?: number;
    userReadList?: string;
    discussionMessages?: {
      discussionMessageID?: number;
      discussionMessageText?: string;
      discussionMessageLeave?: number;
      discussionMessageCreationdate?: string;
      userID?: number;
      userFirstname?: string;
      userLastname?: string;
      discussionMessageUnreadCount?: number;
      discussionMessageReadCount?: number;
      files?: {
        fileID?: number;
        file?: string;
      }[];
    }[];
    users?: string[];
  };
};
export type GetByAffiliationIdResponse = {
  result?: number;
  data?: {
    documentID?: number;
    documentName?: string;
    documentFile?: string;
    documentGroupID?: number;
    documentGroupName?: string;
    documentCreationdate?: string;
  };
};
export type SetImportAdminResponse = {
  result?: number;
  data?: {
    documentID?: string;
    documentCustomerID?: string;
    documentUploadUrl?: string;
  }[];
};
export type GetAllByDateAdminResponse = {
  result?: number;
  data?: {
    documentID?: number;
    documentName?: string;
    documentCreationdate?: string;
  }[];
};
export type DeleteMultipleAdminResponse = {
  result?: number;
};
export type GetAffiliationAdminResponse = {
  result?: number;
  data?: {
    documentID?: number;
    documentName?: string;
    documentFile?: string;
    documentExtension?: string;
    documentAffiliationID?: number;
    documentCreationdate?: string;
    documentExpireDate?: string;
    documentGroupID?: number;
    documentGroupName?: string;
  }[];
};
export type GetGuildAllResponse = {
  result?: number;
  data?: {
    companyGuildID?: number;
    companyGuildName?: string;
  }[];
};
export type CompanyGetAllResponse = {
  result?: number;
  data?: {
    companyTypeID?: number;
    companyTypeName?: string;
    companyUseName?: number;
    guildID?: number;
    guildName?: string;
    companies?: {
      companyID?: number;
      companyName1?: string;
      companyName2?: string;
      companyName3?: string;
      companyStreet?: string;
      companyZip?: string;
      companyLocation?: string;
      companyCustomerID?: string;
      companyMe?: number;
      userID?: number;
      userFirstname?: string;
      userLastname?: string;
      userGender?: number;
      userMail?: string;
      companyTypes?: string[];
    }[];
  }[];
};
export type GetUserAllResponse = {
  result?: number;
  data?: {
    companyTypeID?: number;
    companyTypeName?: string;
    companyUseName?: number;
    guildID?: number;
    guildName?: string;
    companies?: {
      companyID?: number;
      companyName1?: string;
      companyName2?: string;
      companyName3?: string;
      companyStreet?: string;
      companyZip?: string;
      companyLocation?: string;
      companyMe?: string;
      userID?: number;
      userFirstname?: string;
      userLastname?: string;
      userGender?: number;
      userMail?: string;
      companyTypes?: string[];
    }[];
  }[];
};
export type DeleteNavigationAdminResponse = {
  result?: number;
};
export type GetNavigationAdminResponse = {
  result?: number;
  data?: {
    navigationName?: string;
    navigationText?: string;
    navigationModule?: string;
    navigationIcon?: string;
  };
};
export type GetNavigationAllAdminResponse = {
  result?: number;
  data?: {
    navigationID?: number;
    navigationName?: string;
    navigationText?: string;
    navigationModule?: string;
    navigationIcon?: string;
    navigationOrder?: number;
    navigationCreationdate?: string;
  }[];
};
export type SetNavigationAdminResponse = {
  result?: number;
};
export type SetNavigationOrderAdminResponse = {
  result?: number;
};
export type DeleteTypeAdminResponse = {
  result?: number;
};
export type GetTypeAllResponse = {
  result?: number;
  data?: {
    companyTypeID?: number;
    companyTypeName?: string;
  }[];
};
export type SetTypeAdminResponse = {
  result?: number;
};
export type SetTypeOrderAdminResponse = {
  result?: number;
};
export type SetGuildResponse = {
  result?: number;
};
export type GetTypeAllAdminResponse = {
  result?: number;
  data?: {
    companyTypeID?: number;
    companyTypeName?: string;
    companyTypeOrder?: string;
    companyTypeCreationdate?: string;
  }[];
};
export type GetTypeAdminResponse = {
  result?: number;
  data?: {
    companyTypeID?: number;
    companyTypeName?: string;
  };
};
export type GetEditResponse = {
  result?: number;
  data?: {
    discussionID?: number;
    discussionName?: string;
    discussionPrivate?: number;
    discussionDescription?: string;
    discussionCreationdate?: string;
    userCount?: number;
    discussionCompany?: {
      userID?: number;
      userFirstName?: string;
      userLastName?: string;
      companyID?: number;
      companyName1?: string;
      companyName2?: string;
      companyName3?: string;
      companySelected?: number;
    }[];
  };
};
export type DiscussionAddResponse = {
  result?: number;
  error?: string;
};
export type EditDiscussionResponse = {
  result?: number;
  error?: string;
};
export type AddMessageResponse = {
  result?: number;
  error?: string;
};
export type LeaveResponse = {
  result?: boolean;
};
export type SetMessagesReadResponse = {
  result?: boolean;
};
export type NewsGetAllResponse = {
  result?: number;
  data?: {
    newsID?: number;
    newsName?: string;
    newsDescription?: string;
    newsImage?: string;
    newsComments?: number;
    newsCreationdate?: string;
    newsCommentCount?: number;
    newsUnreadCount?: number;
    newsGuilds?: string[];
    documents?: string[];
    documentID?: number;
    documentFiles?: string[];
    documentExtension?: string[];
  }[];
};
export type GetNewsResponse = {
  result?: number;
  data?: {
    newsID?: number;
    newsName?: string;
    newsDescription?: string;
    newsImage?: string;
    newsComments?: number;
    newsCreationdate?: string;
    newsUnreadCount?: number;
    newsGuilds?: string[];
    newsDocuments?: {
      documentID?: number;
      documentFile?: string;
      documentName?: string;
      documentExtension?: string;
    }[];
    documentID?: number;
    documentFile?: string;
    documentExtension?: string;
    appointmentID?: number;
    appointmentName?: string;
    appointmentDate?: string;
  };
  comments?: {
    newsCommentID?: number;
    newsCommentText?: string;
    newsCommentCreationdate?: string;
    userID?: number;
    userFirstname?: string;
    userLastname?: string;
  }[];
};
export type GetCommentAllAdminResponse = {
  result?: number;
  data?: {
    newsCommentID?: number;
    newsCommentText?: string;
    newsCommentCreationdate?: string;
    userID?: number;
    userFirstname?: string;
    userLastname?: string;
  }[];
};
export type AddCommentResponse = {
  result?: number;
  error?: string;
};
export type DeleteCommentAdminResponse = {
  result?: number;
};
export type GetDocumentsResponse = {
  result?: number;
  data?: {
    documentID?: number;
    documentName?: string;
    documentFile?: string;
    documentCreationdate?: string;
    documentGroupID?: number;
    documentGroupName?: string;
    documentGroupIcon?: string;
    documentGroupCreationdate?: string;
  }[];
};
export type AutodeleteNewsResponse = {
  result?: number;
};
export type XxResponse = {
  data?: {
    companyID?: number;
    companyName1?: string;
    companyName2?: string;
    companyName3?: string;
    propertyTabID?: number;
    propertyTabName?: string;
    propertyTabBlocks?: {
      propertyTabBlockID?: number;
      propertyTabBlockName?: string;
      propertyBlockFields?: {
        propertyFieldID?: number;
        propertyFieldName?: string;
        propertyFieldTypeID?: string;
        propertyFieldTypeName?: string;
        selectedTextFieldID?: string;
        selectedTextField?: string;
        selectedOptionFieldID?: number;
        selectedOptionField?: string;
      }[];
    }[];
  }[];
};
export type SetTemplateAdminResponse = {
  result?: number;
};
export const {
  useStatisticGetProductivityQuery,
  useStatisticGetCoreQuery,
  useAssociationGetStyleQuery,
  useAssociationGetStyleByDomainQuery,
  useAssociationGetAllQuery,
  useAssociationGetQuery,
  useAssociationSetMutation,
  useAssociationDeleteMutation,
  useAssociationSetDomainAdminMutation,
  useAssociationSetUseTemplateAdminMutation,
  useAssociationSetTemplateFileAdminMutation,
  useAssociationDeleteImageAdminMutation,
  useAssociationGetAdminUserAllQuery,
  useAssociationGetImagesAllAdminQuery,
  useAssociationAddImageAdminMutation,
  useAssociationSetLetterAdminMutation,
  useAssociationGetLetterAllAdminQuery,
  useAssociationGetLetterAdminQuery,
  useAssociationDeleteAssociationLetterAdminMutation,
  useAssociationSetGeneralMutation,
  useAssociationSetSettingsModeMutation,
  useAssociationSetGuildAdminMutation,
  useAssociationDeleteGuildAdminMutation,
  useAssociationGetGuildAdminQuery,
  useAssociationGetGuildReceivedAllAdminQuery,
  useAssociationGetGuildSharedAdminAllQuery,
  useAssociationGetErrorsCountQuery,
  useAssociationGetErrorsQuery,
  useAssociationSetErrorResendMutation,
  useAssociationSetErrorDeleteMutation,
  useLinkGetAllQuery,
  useLinkGetAllAdminQuery,
  useLinkGetListAdminQuery,
  useLinkGetAdviceAllAdminQuery,
  useLinkDeleteAdminMutation,
  useLinkDeleteAdviceAdminMutation,
  useLinkSetGroupAdminMutation,
  useLinkDeleteGroupAdminMutation,
  useLinkGetGroupAllAdminQuery,
  useLinkSetAdminMutation,
  useLinkGetAdminQuery,
  useLinkGetAdviceAdminQuery,
  useLinkGetGroupAdminQuery,
  usePropertyGetDataAllAdminQuery,
  usePropertyGetDataAllQuery,
  usePropertySetDataAllMutation,
  usePropertyGetTabDataAdminQuery,
  usePropertySetFieldOptionAdminMutation,
  usePropertySetFieldOptionPropertyAdminMutation,
  usePropertySetFieldAdminMutation,
  usePropertySetValueAdminMutation,
  usePropertyGetFieldAdminQuery,
  usePropertyGetFieldTypeAdminAllQuery,
  usePropertyGetTabAdminAllQuery,
  usePropertyGetTabTypeAllAdminQuery,
  usePropertyGetTabAdminQuery,
  usePropertySetTabAdminMutation,
  usePropertyDeleteTabAdminMutation,
  usePropertyDeleteFieldAdminMutation,
  usePropertyDeleteOptionAdminMutation,
  usePropertySetTabOrderAdminMutation,
  usePropertySetFieldOrderAdminMutation,
  usePropertyGetTabBlockAdminAllQuery,
  usePropertyGetTabBlockAdminQuery,
  usePropertySetTabBlockAdminMutation,
  usePropertyAddTabDocumentGroupAdminMutation,
  usePropertyGetTabDocumentGroupsAdminQuery,
  usePropertyDeleteTabBlockAdminMutation,
  usePropertySetTabBlockOrderAdminMutation,
  usePropertyGetTabDocumentAllAdminQuery,
  usePropertyGetTabTimelineAllAdminQuery,
  usePropertySetTabDocumentAdminMutation,
  usePropertySetTabTimelineAdminMutation,
  usePropertyDeleteTabTimelineAdminMutation,
  usePropertyDeleteTabDocumentAdminMutation,
  usePropertyDeleteTabTimelineFileAdminMutation,
  usePropertyGetExportDataAdminQuery,
  usePropertyAddExportPresetItemAdminMutation,
  usePropertyAddExportPresetAdminMutation,
  usePropertyGetExportPresetAdminQuery,
  useUserLoginMutation,
  useUserIsValidApikeyQuery,
  useUserGetNavigationQuery,
  useUserGetNavigationItemQuery,
  useUserSetNavigationCountEmptyMutation,
  useUserGetDetailsQuery,
  useUserGetDeviceAdminQuery,
  useUserDeleteDeviceMutation,
  useUserAddDeviceMutation,
  useUserSendLoginAdminMutation,
  useUserGetAllAdminQuery,
  useUserGetAdminAllAdminQuery,
  useUserGetAdminQuery,
  useUserSetAdminMutation,
  useUserDeleteUserAdminMutation,
  useUserAddGuildAdminMutation,
  useUserDeleteAdminMutation,
  useUserDeleteGuildAdminMutation,
  useUserSetPasswordRequestMutation,
  useUserResetPasswordMutation,
  useUserSetChangesMutation,
  useUserGetChangesQuery,
  useUserGetChangesAllAdminQuery,
  useUserSetChangeAdminMutation,
  useUserDeleteChangesAdminMutation,
  useUserGetByActivationKeyQuery,
  useMailGetAccountAllAdminQuery,
  useMailGetAccountAdminQuery,
  useMailGetAccountTypeAllAdminQuery,
  useMailDeleteAccountAdminMutation,
  useMailSetAccountActiveAdminMutation,
  useMailSetSignatureActiveAdminMutation,
  useMailAddSignatureAdminMutation,
  useMailGetSignatureAllAdminQuery,
  useMailGetSignatureAdminQuery,
  useMailEditSignatureAdminMutation,
  useMailDeleteSignatureAdminMutation,
  useMailGetMailContentAdminQuery,
  useMailSetMailAdminMutation,
  useMailSetAccountAdminMutation,
  useMailSetAccountAdminOffice365Mutation,
  useMailGetTemplatesAllAdminQuery,
  useNotificationGetTemplatesAllQuery,
  useNotificationGetTemplateQuery,
  useNotificationDeleteTemplateMutation,
  useNotificationAddTemplateEmptyMutation,
  useNotificationSetTemplateMutation,
  useNotificationAddTestMutation,
  useNotificationAutoDeleteMutation,
  useNotificationGenerateEpostFilesQuery,
  useNotificationConvertPdfFileMutation,
  useNotificationMergePdfFilesMutation,
  useNotificationResendMutation,
  useNotificationGetReceiverCountsQuery,
  useNotificationSetReceiverCountsMutation,
  useNotificationGetImageInfoQuery,
  useNotificationGetTypesQuery,
  useNotificationSetTypesMutation,
  useNotificationGetUserAllAdminQuery,
  useNotificationSetUserAllAdminMutation,
  useNotificationDeleteUserAdminMutation,
  useNotificationAddUserAdminMutation,
  useNotificationAddTestMailMutation,
  useNotificationRefreshAccessTokensMutation,
  useExceptionExceptionMutation,
  useGuildGetAllAdminQuery,
  useGuildGetAdminAllAdminQuery,
  useGuildGetAdminQuery,
  useGuildSetAdminMutation,
  useGuildDeleteAdminMutation,
  useGuildSetNotifictionTemplateMutation,
  useGuildGetNotificationTemplateQuery,
  useGuildGetUserAllAdminQuery,
  useGuildAddAdminAdminMutation,
  useGuildDeleteAdminAdminMutation,
  useGeneralGetChangelogQuery,
  useAppointmentGetAllQuery,
  useAppointmentSetAppointmentMutation,
  useAppointmentGetAdviceAllAdminQuery,
  useAppointmentGetAdviceAdminQuery,
  useAppointmentGetQuery,
  useAppointmentSetMutation,
  useAppointmentGetUsersQuery,
  useAppointmentDeleteDocumentsAdminMutation,
  useAppointmentSetStatusMutation,
  useAppointmentGetAllAdminQuery,
  useAppointmentDeleteAdminMutation,
  useAppointmentDeleteAdviceAdminMutation,
  useAppointmentShowIcsFrameQuery,
  useAppointmentGetUserAllAdminQuery,
  useMediaGetAllQuery,
  useMediaGetQuery,
  useMediaDeleteAdminMutation,
  useMediaGetUserAllAdminQuery,
  useMediaSetAdminMutation,
  useMediaGetAdminQuery,
  useMediaSetViewCountMutation,
  useRssGetAllAdminQuery,
  useRssDeleteAdminMutation,
  useRssGetAdminQuery,
  useRssSetAdminMutation,
  useRssFetchChannelsQuery,
  useFileUploadMutation,
  useFileGeneratePdfMutation,
  useImportGetFieldsQuery,
  useImportGetPresetsQuery,
  useImportGetPresetQuery,
  useImportAddMutation,
  useImportAddExcelMutation,
  useImportSetProcessActiveMutation,
  useImportSetProcessInActiveMutation,
  useDocumentGetGroupAllQuery,
  useDocumentGetGroupAllAdminQuery,
  useDocumentSetGroupAdminMutation,
  useDocumentDeleteGroupAdminMutation,
  useDocumentGetGroupQuery,
  useDocumentGetAllQuery,
  useDocumentGetQuery,
  useDocumentGetByAffiliationIdQuery,
  useDocumentSetAdminMutation,
  useDocumentSetImportAdminMutation,
  useDocumentGetAllAdminQuery,
  useDocumentGetAllByDateAdminQuery,
  useDocumentGetAdviceAllAdminQuery,
  useDocumentDeleteAdminMutation,
  useDocumentDeleteMultipleAdminMutation,
  useDocumentDeleteAdviceAdminMutation,
  useDocumentGetAdminQuery,
  useDocumentGetAffiliationAdminQuery,
  useDocumentGetAdviceAdminQuery,
  useDocumentGetUserAllAdminQuery,
  useCompanyGetGuildAllQuery,
  useCompanyGetUserAllAdminQuery,
  useCompanyGetAllQuery,
  useCompanyGetUserAllQuery,
  useCompanyDeleteNavigationAdminMutation,
  useCompanyGetNavigationAdminQuery,
  useCompanyGetNavigationAllAdminQuery,
  useCompanySetNavigationAdminMutation,
  useCompanySetNavigationOrderAdminMutation,
  useCompanyDeleteTypeAdminMutation,
  useCompanyGetTypeAllQuery,
  useCompanySetTypeAdminMutation,
  useCompanySetTypeOrderAdminMutation,
  useCompanySetGuildMutation,
  useCompanyGetAllAdminQuery,
  useCompanyDeleteAdminMutation,
  useCompanyDeleteChangesAdminMutation,
  useCompanyGetTypeAllAdminQuery,
  useCompanyGetTypeAdminQuery,
  useCompanyGetAdminQuery,
  useCompanySetAdminMutation,
  useCompanyGetUsersQuery,
  useDiscussionGetAllQuery,
  useDiscussionGetQuery,
  useDiscussionGetEditQuery,
  useDiscussionAddMutation,
  useDiscussionEditMutation,
  useDiscussionAddMessageMutation,
  useDiscussionDeleteMutation,
  useDiscussionLeaveMutation,
  useDiscussionSetMessagesReadMutation,
  useNewsGetAllQuery,
  useNewsGetQuery,
  useNewsGetCommentAllAdminQuery,
  useNewsAddCommentMutation,
  useNewsDeleteCommentAdminMutation,
  useNewsDeleteDocumentsAdminMutation,
  useNewsDeleteAdminMutation,
  useNewsDeleteAdviceAdminMutation,
  useNewsGetUserAllAdminQuery,
  useNewsSetAdminMutation,
  useNewsGetDocumentsQuery,
  useNewsGetAdviceAdminQuery,
  useNewsAutodeleteNewsMutation,
  usePropertyXxQuery,
  useMailSetTemplateAdminMutation,
} = injectedRtkApi;
